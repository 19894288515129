/* eslint-disable react/no-unstable-nested-components */
import classnames from 'classnames'
import Button from 'components/button'
import Table from 'components/table'
import { useAuth } from 'modules/auth/context/useAuth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaBolt, FaLeaf, FaRegListAlt } from 'react-icons/fa'
import { GrFormClose } from 'react-icons/gr'

import GetPricingStatusText from 'utils/pricingStatus'

import Loading from 'components/loading'
import { orderBy } from 'lodash'
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { RiTruckFill } from 'react-icons/ri'
import { AddConfigurationOnQuote } from '../components/AddConfigurationOnQuote'
import AddNovoConfiguration from '../components/AddNovoConfiguration'
import QuoteAction from '../components/QuoteAction'

import { useQuoteLayout } from '../QuoteLayout'
import QuoteExtraQuickView from '../components/QuoteExtraQuickView'
import { reorderConfiguration } from '../queries/quotes'

type EditConfigType = {
    id: number
    contract_number: string
    serial_number: string
    staring_date: Date | null
}

type ScreenType =
    | 'list'
    | 'add-configuration'
    | 'upload-document'
    | 'requirement-matrix'
    | 'add-novo-configuration'

const QuoteDetail = ({ readonly }: { readonly?: boolean }) => {
    const {
        quoteConfigurations,
        recalculateQuoteMutation,
        isLoading,
        quoteDetail,
        refetch,
        setCurrentConfiguration,
    } = useQuoteLayout()
    const { user } = useAuth()
    const { t } = useTranslation()
    const [currentScreen, setCurrentScreen] = useState<ScreenType>('list')
    const [configurationLoading, setConfigurationLoading] = useState(false)
    const [configurations, setConfigurations] = useState<any[]>([])

    useEffect(() => {
        setConfigurationLoading(true)
        if (quoteConfigurations) {
            setConfigurations(
                quoteConfigurations?.map((item: any, index) => ({ ...item, order: index + 1 })),
            )
        }
        setConfigurationLoading(false)
    }, [quoteConfigurations])

    const reorderConfigurations = useMutation(
        ['reorderConfigurations'],
        (params: { configuration_ids: number[] }) => reorderConfiguration(params),
        {
            onSuccess: () => {
                toast.success(t('message.reorder_success'))
                refetch()
            },
            onError: () => {
                toast.error(t('message.something_went_wrong'))
            },
        },
    )

    const columns = [
        {
            name: t('api.id'),
            left: true,
            width: '4rem',
            selector: (row: { id: string }) => {
                return <p className="text-black text-bold">{row.id || '-'}</p>
            },
        },
        {
            name: '',
            width: '6rem',
            selector: (row: {
                description: string
                lithium_battery: boolean
                is_novo: boolean
                is_electric: boolean
                stock_id: number
                is_used_truck: boolean
            }) => {
                return (
                    <div className="py-2 flex items-center gap-2">
                        <p
                            className="text-red-500 text-lg text-bold cursor-pointer"
                            title="Novo Configuration"
                        >
                            {/* N: Novo, C: cpq */}
                            {row.is_novo ? 'N' : 'C'}
                        </p>
                        {row.lithium_battery && (
                            <div
                                title="This configuration is based on Lithium Battery"
                                className="text-green-700 cursor-pointer"
                            >
                                <FaLeaf size={15} />
                            </div>
                        )}
                        {row.is_electric && (
                            <div
                                title="This configuration is based on Electric"
                                className="text-yellow-400 cursor-pointer"
                            >
                                <FaBolt size={15} />
                            </div>
                        )}
                        {row.stock_id && (
                            <div
                                title="This configuration is based on Stock"
                                className="text-green-400 cursor-pointer"
                            >
                                <FaRegListAlt size={15} />
                            </div>
                        )}
                        {row.is_used_truck && (
                            <div
                                title="This configuration is based on Used Truck"
                                className="text-red-400 cursor-pointer"
                            >
                                <RiTruckFill size={16} />
                            </div>
                        )}
                    </div>
                )
            },
        },
        {
            name: t('api.novo_quote_number_or_object_number'),
            center: true,
            width: '12rem',
            selector: (row: {
                novo_quote_number: string
                object_number: string
                is_used_truck: boolean
            }) => {
                return (
                    <p
                        title={
                            (row.is_used_truck ? row.object_number : row.novo_quote_number) || '-'
                        }
                        className="text-black text-bold"
                    >
                        {(row.is_used_truck ? row.object_number : row.novo_quote_number) || '-'}
                    </p>
                )
            },
        },
        {
            name: t('api.model'),
            center: true,
            width: '8rem',
            selector: (row: { model: string }) => {
                return <p className="text-black text-bold">{row.model || '-'}</p>
            },
        },
        {
            name: t('api.series'),
            center: true,
            width: '8rem',
            selector: (row: { series: string }) => {
                return <p className="text-black text-bold">{row.series || '-'}</p>
            },
        },
        {
            name: t('api.description'),
            height: 'fit-content',
            wrap: true,
            width: '16rem',
            selector: (row: { description: string }) => (
                <p title={row.description} className="truncate cursor-pointer">
                    {row.description}
                </p>
            ),
        },

        {
            name: t('api.reference'),
            height: 'fit-content',
            wrap: true,
            width: '8rem',
            selector: (row: { reference: string }) => (
                <p title={row.reference} className="truncate cursor-pointer">
                    {row.reference}
                </p>
            ),
        },
        {
            name: t('api.pricing_status'),
            center: true,
            width: '8rem',
            selector: (row: {
                configuration_price_status: number
                stock: any
                stock_id: number
            }) => {
                if (row.stock_id && !row.stock) {
                    return (
                        <p className="whitespace-normal text-center rounded-full px-4 text-white bg-red-400">
                            {t('api.stock_deleted')}
                        </p>
                    )
                }
                return <GetPricingStatusText pendingStatus={row.configuration_price_status} />
            },
        },
        {
            name: t('api.quantity'),
            center: true,
            width: '6rem',
            selector: (row: { quantity: string }) => {
                return <p className="text-black text-bold">{row.quantity}</p>
            },
        },
        {
            name: t('api.extra_view'),
            center: true,
            width: '8rem',
            selector: (row: { quantity: string }) => {
                return <QuoteExtraQuickView configuration={row} />
            },
        },
        {
            name: t('api.order'),
            center: true,
            width: '10rem',
            cell: (row: { id: number; order: number }) => {
                const orderData = (type: 'up' | 'down') => {
                    const tempDataList: any = configurations ? [...configurations] : []
                    const clickedIndex = tempDataList.findIndex((item: any) => item.id === row.id)
                    const clickedItem = tempDataList[clickedIndex]
                    if (type === 'up') {
                        tempDataList[clickedIndex - 1].order = clickedItem.order
                        tempDataList[clickedIndex].order = clickedItem.order - 1
                    } else {
                        tempDataList[clickedIndex + 1].order = clickedItem.order
                        tempDataList[clickedIndex].order = clickedItem.order + 1
                    }
                    setConfigurations([...orderBy(tempDataList, ['order'], ['asc'])])
                }

                return (
                    <div className="flex gap-2">
                        <button
                            type="button"
                            className={`bg-black bg-opacity-80 hover:bg-opacity-50 text-white font-bold py-2 px-4 rounded ${
                                row.order === 1 && 'hidden sr-only'
                            }}`}
                            onClick={() => {
                                orderData('up')
                            }}
                        >
                            <MdArrowUpward />
                        </button>
                        <button
                            type="button"
                            className={`bg-black bg-opacity-80 hover:bg-opacity-50 text-white font-bold py-2 px-4 rounded ${
                                row.order === quoteConfigurations?.length && 'hidden sr-only'
                            }`}
                            onClick={() => {
                                orderData('down')
                            }}
                        >
                            <MdArrowDownward />
                        </button>
                    </div>
                )
            },
        },
        {
            name: t('api.action'),
            right: true,
            width: '10rem',
            omit: readonly,
            selector: (row: { id: string | number; configuration_price_status: number }) => (
                <QuoteAction
                    configuration={row}
                    isSameUser={user.id === quoteDetail.user_id}
                    refetch={refetch}
                    setCurrentConfiguration={setCurrentConfiguration}
                    priceStatus={row.configuration_price_status}
                />
            ),
            sortable: true,
        },
    ]

    const getScreen = () => {
        switch (currentScreen) {
            case 'add-configuration':
                return (
                    <AddConfigurationOnQuote
                        callback={() => {
                            refetch()
                            setCurrentScreen('list')
                        }}
                    />
                )
            case 'add-novo-configuration':
                return (
                    <AddNovoConfiguration
                        callback={() => {
                            refetch()
                            setCurrentScreen('list')
                        }}
                    />
                )
            case 'list':
                return (
                    <Table
                        columns={columns}
                        data={configurations || []}
                        loading={isLoading || configurationLoading}
                        striped
                        pagination
                        totalRows={25}
                        tableHeight="90%"
                    />
                )
            default:
                return (
                    <Table
                        columns={columns}
                        data={configurations || []}
                        loading={isLoading || configurationLoading}
                        striped
                        pagination
                        totalRows={25}
                        tableHeight="90%"
                    />
                )
        }
    }

    return (
        <div className={classnames('flex flex-col gap-8')}>
            <div className="flex flex-col md:flex-row justify-start md:justify-between item-start md:items-center gap-8">
                <p className="text-secondary border-primary font-medium underline underline-offset-8 decoration-4 decoration-primary">
                    {t('api.quote_configuration')}
                </p>
                <div className="flex flex-col md:flex-row items-start md:items-center justify-start md:justify-end gap-4">
                    <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
                        <Button
                            label={t('api.reorder')}
                            customButtonClass="w-full md:w-fit "
                            variant="primary"
                            onClick={() => {
                                reorderConfigurations.mutate({
                                    configuration_ids: configurations.map(obj => obj.id),
                                })
                            }}
                        />
                        {!readonly && (
                            <Button
                                label={t('api.add_novo_configuration')}
                                customButtonClass="w-full md:w-fit "
                                variant={
                                    currentScreen === 'add-novo-configuration'
                                        ? 'outline'
                                        : 'primary'
                                }
                                onClick={() => setCurrentScreen('add-novo-configuration')}
                            />
                        )}
                        {/* Hidden as per requirement. It was decided that configuration in not required in heffiq */}
                        {/* {user.id === quoteDetail.user_id ? (
                            <Button
                                label={t('api.add_configuration')}
                                customButtonClass="w-full md:w-fit"
                                variant={
                                    currentScreen === 'add-configuration' ? 'outline' : 'primary'
                                }
                                onClick={() => setCurrentScreen('add-configuration')}
                            />
                        ) : null} */}
                    </div>
                    {currentScreen !== 'list' && (
                        <Button
                            variant="ghost"
                            customButtonClass="w-full md:w-fit"
                            leftIcon={<GrFormClose size={30} />}
                            onClick={() => setCurrentScreen('list')}
                        />
                    )}
                </div>
            </div>
            {recalculateQuoteMutation.isLoading || recalculateQuoteMutation.isFetching ? (
                <div className="flex flex-col items-center">
                    <Loading />
                    {t('message.recalculating')}
                </div>
            ) : (
                getScreen()
            )}
        </div>
    )
}

export default QuoteDetail
