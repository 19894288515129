/* eslint-disable @typescript-eslint/no-explicit-any */
import { callAxios } from 'plugins/api'
import { useContext, createContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LOGIN_ROUTE } from 'route/appRoutes'
import { errorToast, successToast } from '../../../utils/toasterUtil'
import { AuthQueries } from './auth'

export type ResetPasswordProps = {
    email: string
    password: string
    password_confirmation: string
    token: string
}

export type ForgotPasswordProps = {
    email: string
}

const authReducer: any = {
    isUserLoggedIn: false,
    country: {},
    loading: false,
    user: {
        id: '',
        name: '',
        email: '',
    },
    // func
}

export const ROLE_TYPE = {
    account_manager: 2,
    field_sales_manager: 3,
    admin: 1,
    key_account_manager: 4,
    technical_support: 5,
    servicer: 6,
    sales_manager: 7,
    fleet: 8,
    service_manager: 9,
    managing_director: 10,
    sales_support: 11,
    used_truck_support: 12,
}

const authContext = createContext<any>(authReducer)

const { Provider } = authContext

const loginApi = 'auth/azure/signin'

const useAuthProvider = () => {
    const [user, setUser] = useState<any>({} as any)
    const [country, setCountry] = useState<any>({} as any)
    const [userExtra, setUserExtra] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [authorizationMatrix, setAuthorizationMatrix] = useState<any>(false)
    const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const getAuthorizationMatrix = useMutation(
        ['getAuthorizationMatrix'],
        (roleId: number) => AuthQueries.getAuthorizationMatrixByRoleId(roleId),
        {
            onSuccess: data => {
                setAuthorizationMatrix(data?.data?.data)
                localStorage.setItem('CPQ_AUTHORIZATION_MATRIX', JSON.stringify(data?.data?.data))
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.get_pricing_failed'))
            },
        },
    )

    const logoutUser = () => {
        localStorage.removeItem('CPQ_LOGGED_IN_USER')
        localStorage.removeItem('CPQ_ACCESS_TOKEN')
        localStorage.clear()
        setUser({} as any)
        setCountry({} as any)
        setIsUserLoggedIn(false)
        setAuthorizationMatrix([])
        navigate(LOGIN_ROUTE)
    }

    const loginUser = async () => {
        setLoading(true)
        await callAxios({
            url: loginApi,
            method: 'GET',
        })
            .then(res => {
                if (res.data) {
                    window.location.replace(res.data)
                } else {
                    toast.error('Login Failed')
                }
            })
            .catch(err => {
                toast(err?.message || 'Login Failed', errorToast)
                logoutUser()
            })
        setLoading(false)
    }

    const checkAuthTokenInUrl = () => {
        const { search } = window.location
        const params = new URLSearchParams(search)
        const token = params.get('token')
        return token
    }

    const getAuthUserDetail = useMutation(
        ['auth.login_user'],
        () => AuthQueries.getAuthUserDetails(),
        {
            retry: 0,
            onSuccess: data => {
                if (data.data.role === 1) {
                    const tokenFromUrl = checkAuthTokenInUrl()
                    window.location.replace(
                        `${process.env.REACT_APP_ADMIN_URL}?token=${tokenFromUrl}`,
                    )
                }
                localStorage.setItem('CPQ_LOGGED_IN_USER', JSON.stringify(data?.data?.user))
                localStorage.setItem(
                    'CPQ_LOGGED_IN_USER_COUNTRY',
                    JSON.stringify(data?.data.country[0]),
                )
                localStorage.setItem('CPQ_LOGGED_IN_USER_EXTRA_INFO', JSON.stringify(data?.data))
                setCountry(data?.data.country[0])
                setUser(data?.data?.user)
                setUserExtra(data?.data)
                getAuthorizationMatrix.mutate(data?.data?.role_id)
                setIsUserLoggedIn(true)
                toast(data?.data?.message || 'Login Success', successToast)
            },
            onError: (error: { message: string }) => {
                const tokenFromUrl = checkAuthTokenInUrl()
                localStorage.setItem('CPQ_ACCESS_TOKEN', JSON.stringify(tokenFromUrl) || '')
                if (error?.message === 'Role not found') {
                    navigate(`auth/role-selector?token=${tokenFromUrl}`)
                } else {
                    toast(error?.message || 'Login failed', errorToast)
                }
            },
        },
    )

    const checkAuthentication = async () => {
        setLoading(true)
        const tokenFromUrl = checkAuthTokenInUrl()
        if (tokenFromUrl) {
            await localStorage.setItem('CPQ_ACCESS_TOKEN', JSON.stringify(tokenFromUrl))
            getAuthUserDetail.mutate()
        } else {
            const loginUserInSession = (await localStorage.getItem('CPQ_LOGGED_IN_USER')) || ''
            const loginUserExtraInfo =
                (await localStorage.getItem('CPQ_LOGGED_IN_USER_EXTRA_INFO')) || ''
            const loginUserInCountry =
                (await localStorage.getItem('CPQ_LOGGED_IN_USER_COUNTRY')) || ''
            const accessToken = (await localStorage.getItem('CPQ_ACCESS_TOKEN')) || ''
            const authorizationMatrixInLocalStorage =
                (await localStorage.getItem('CPQ_AUTHORIZATION_MATRIX')) || ''
            if ((loginUserInSession && accessToken) || tokenFromUrl) {
                await setUser(JSON.parse(loginUserInSession))
                await setUserExtra(JSON.parse(loginUserExtraInfo))
                await setCountry(JSON.parse(loginUserInCountry))
                await setAuthorizationMatrix(JSON.parse(authorizationMatrixInLocalStorage))
                await setIsUserLoggedIn(true)
            }
        }
        setLoading(false)
    }

    const selectRole = useMutation(
        ['auth.select_role'],
        (roleId: number) => AuthQueries.updateRole(roleId),
        {
            onSuccess: data => {
                window.location.replace(data.data.link)
            },
            onError: (error: Error) => {
                toast(error?.message || 'Select User failed', errorToast)
            },
        },
    )

    useEffect(() => {
        checkAuthentication()
    }, [])

    return {
        user,
        userExtra,
        loading: loading || getAuthUserDetail.isLoading,
        isUserLoggedIn,
        country,
        authorizationMatrix,

        // functions
        loginUser,
        logoutUser,
        selectRole,
    }
}

// provider hoc
export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
    const auth = useAuthProvider()
    return <Provider value={auth}>{children}</Provider>
}

export const useAuth = () => useContext(authContext)
