import { Checkbox } from 'antd'
import Loading from 'components/loading'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'
import { useMutation, useQuery } from 'react-query'
import { Modal } from 'react-responsive-modal'
import { toast } from 'react-toastify'
import GetUsedTruckStatusText, { USED_TRUCK_STATUS } from 'utils/UsedTruckStatus'
import { getUsedTruckReservationDetail, reserveUsedTruck, unReserveUsedTruck } from '../query'

interface UsedTruckInfoViewProps {
    configurationId: number
    objectNumber: string
    description: string
}

const UsedTruckInfoView = (props: UsedTruckInfoViewProps) => {
    const { configurationId, objectNumber, description } = props
    const { userExtra, user } = useAuth()
    const [reservedQty, setReservedQty] = useState<number>(0)
    const [soldQty, setSoldQty] = useState<number>(0)
    const { t } = useTranslation()
    const [showUsedTruckInfoModal, setShowUsedTruckInfoModal] = useState<boolean>(false)

    const { data, isLoading, isRefetching, refetch } = useQuery(
        ['stock.get_used_truck_detail', objectNumber],
        () => getUsedTruckReservationDetail(objectNumber),
        {
            enabled: showUsedTruckInfoModal,
            onSuccess: (d: any) => {
                if (d?.data) {
                    setReservedQty(d?.data?.reserved_count)
                    setSoldQty(d?.data?.sold_count)
                }
            },
            onError: (error: Error) => {
                toast.error(error?.message || t('api.get_stock_detail_error'))
            },
        },
    )

    const handleUnReserve = useMutation(
        ['stock.reserve_stock'],
        (params: { configurationId: number }) => unReserveUsedTruck(params.configurationId),
        {
            onSuccess: () => {
                setReservedQty(prevQty => prevQty - 1)
                toast.success(t('api.stock_revoke_success'))
            },
            onError: (error: any) => {
                toast.error(error?.message || t('api.stock_revoke_error'))
                refetch()
            },
        },
    )

    const handleReserve = useMutation(
        ['stock.revoke_stock'],
        (params: { configurationId: number }) => reserveUsedTruck(params.configurationId),

        {
            onSuccess: () => {
                setReservedQty(prevQty => prevQty + 1)
                toast.success(t('api.stock_reserve_success'))
            },
            onError: (error: any) => {
                toast.error(error?.message || t('api.stock_revoke_error'))
                refetch()
            },
        },
    )

    return (
        <>
            <div
                className="cursor-pointer"
                onClick={() => {
                    setShowUsedTruckInfoModal(true)
                }}
            >
                <FaInfoCircle />
            </div>
            <Modal
                open={showUsedTruckInfoModal}
                onClose={() => {
                    setShowUsedTruckInfoModal(false)
                }}
                classNames={{
                    root: 'rounded-lg',
                    modal: 'rounded-lg w-[800px]',
                }}
                showCloseIcon={false}
                center
            >
                <div className="flex flex-col gap-4">
                    <p className="font-bold">{t('api.stock_detail')}</p>
                    <div className="flex gap-2 items-end flex-col">
                        <p>
                            {t('api.novo_quote_number')} : {objectNumber}
                        </p>
                        <p className="w-full text-slate-500">{description}</p>
                    </div>
                    <div className="flex gap-4 justify-between">
                        <div className="flex flex-1 rounded-md border-2 border-slate-200 p-2 flex-col gap-1">
                            <p className="text-sm text-slate-500">{t('api.available_stock_qty')}</p>
                            <p className="text-xl">{1 - soldQty}</p>
                        </div>
                        <div className="flex flex-1 rounded-md border-2 border-slate-200 p-2 flex-col gap-1">
                            <p className="text-sm text-slate-500">{t('api.reserved_qty')}</p>
                            <p className="text-xl">{reservedQty}</p>
                        </div>
                        <div className="flex flex-1 rounded-md border-2 border-slate-200 p-2 flex-col gap-1">
                            <p className="text-sm text-slate-500">{t('api.sold_qty')}</p>
                            <p className="text-xl">{soldQty}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="w-full flex gap-4">
                        <p className="w-80">{t('api.name')}</p>
                        <p className="w-44">{t('api.quote_for')}</p>
                        <p className="w-24">{t('api.qty')}</p>
                        <p className="w-32 flex justify-end">{t('api.reserve')}</p>
                    </div>
                    <div className="flex flex-col gap-4 pr-1 overflow-auto max-h-96">
                        {(isLoading || isRefetching) && (
                            <div className="flex w-full justify-center items-center">
                                <Loading />
                            </div>
                        )}
                        {!(isLoading || isRefetching) &&
                            data?.data.data.map((item: any) => (
                                <div
                                    key={item.id}
                                    className="w-full py-1 border-slate-100 border-b-2 items-center flex gap-4"
                                >
                                    <div className="flex flex-col justify-end w-80">
                                        <p>{item.created_by.name}</p>
                                        <p className="text-sm text-slate-500">
                                            {item.created_by.email}
                                        </p>
                                    </div>
                                    <p className="w-44">{item?.quote?.quotation_for}</p>
                                    <p className="w-12 text-center">{item.quantity}</p>
                                    <div className="flex-1 flex justify-end">
                                        {item?.configuration_price?.used_truck_status ===
                                        USED_TRUCK_STATUS.SOLD ? (
                                            <GetUsedTruckStatusText
                                                pendingStatus={USED_TRUCK_STATUS.SOLD}
                                            />
                                        ) : (
                                            <Checkbox
                                                defaultChecked={
                                                    item?.configuration_price?.used_truck_status ===
                                                    USED_TRUCK_STATUS.RESERVED
                                                }
                                                disabled={
                                                    Number(item?.created_by?.id) !==
                                                        Number(user.id) &&
                                                    userExtra.role_id !== ROLE_TYPE.sales_support &&
                                                    userExtra.role_id !==
                                                        ROLE_TYPE.managing_director
                                                }
                                                onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        handleReserve.mutate({
                                                            configurationId: item.id,
                                                        })
                                                    } else {
                                                        handleUnReserve.mutate({
                                                            configurationId: item.id,
                                                        })
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default UsedTruckInfoView
