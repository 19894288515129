/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
import { Input as AInput, Select, Table, TablePaginationConfig } from 'antd'
import classNames from 'classnames'
import Input from 'components/input'
import Fuse from 'fuse.js'

import Button from 'components/button'
import Loading from 'components/loading'
import Tabs, { TabType } from 'components/tabs'
import { orderBy } from 'lodash'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { ChangeEvent, useEffect, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { IoCloudUpload } from 'react-icons/io5'
import { useMutation, useQuery } from 'react-query'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { USED_TRUCK_ROUTE } from 'route/appRoutes'
import { formatCurrency, getCurrencySymbol } from 'utils/currency/formatCurrency'
import useTranslationData from '../translation/hooks/useTranslationData'
import ExcelTable from './components/ExcelTable'
import {
    downloadUsedTruckInfo,
    getAllStockInfo,
    getAllUsedTruckInfo,
    getAllUsedTruckStockInfo,
    getNovoConfigurationListByUser,
    getNovoUsedConfigurationListByUser,
    getStockInfoColumn,
    getStockList,
    getUsedTruckStockList,
    saveStockInfoByNovoQuoteNumber,
    saveStockInfoColumnNameById,
} from './query'
import UsedTruckListAction from './components/UsedTurckListAction'

const cardClass = 'bg-white p-4 rounded-md flex flex-col h-full'

const NovoConfiguration = () => {
    const {
        translation: { t },
    } = useTranslationData()
    const { userExtra } = useAuth()
    const [isUsedTruck, setIsUsedTruck] = useState<boolean>()
    const [status, setStatus] = useState('all')
    const [connectedStatus, setConnectedStatus] = useState('not-connected')
    const [query, setSearchQuery] = useState<string>('')

    const [stockInfo, setStockInfo] = useState<any>([])
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 20,
    })

    const isUserAuthoriazed =
        userExtra.role_id === ROLE_TYPE.sales_support ||
        userExtra.role_id === ROLE_TYPE.sales_manager

    const tabs: TabType[] = []

    if (!isUsedTruck) {
        tabs.splice(0, tabs.length)
        tabs.push({
            name: 'api.novo_configuration',
            value: 'novo_configuration',
        })
        tabs.push({
            name: 'api.stock',
            value: 'stock',
        })
        tabs.push({
            name: 'api.stock_info',
            value: 'stock_info',
        })
    }

    if (isUsedTruck) {
        tabs.splice(0, tabs.length)
        tabs.push({
            name: 'api.used_truck_configuration',
            value: 'used_truck_configuration',
        })
        tabs.push({
            name: 'api.used_truck_details',
            value: 'used_truck_details',
        })
    }

    const [activeTab, setActiveTab] = useState(tabs[0])

    useEffect(() => {
        if (isUsedTruck) {
            setActiveTab({
                name: 'api.used_truck_configuration',
                value: 'used_truck_configuration',
            })
        } else {
            setActiveTab({
                name: 'api.novo_configuration',
                value: 'novo_configuration',
            })
        }
        setConnectedStatus('all')
    }, [isUsedTruck])

    const {
        isLoading: configurationLoading,
        data: configurationData,
        refetch: configurationRefetch,
    } = useQuery(
        ['getNovoConfigurationList', isUsedTruck, activeTab],
        () =>
            isUsedTruck ? getNovoUsedConfigurationListByUser() : getNovoConfigurationListByUser(),
        {
            enabled:
                activeTab.value === 'novo_configuration' ||
                activeTab.value === 'used_truck_configuration',
            onError: (error: Error) => {
                toast.error(error.message || t('message.configuration_fetch_fail'))
            },
        },
    )

    const {
        isLoading: stockLoading,
        data: stockData,
        refetch: stockRefetch,
    } = useQuery(
        ['getStockList', isUsedTruck, activeTab],
        () => (isUsedTruck ? getUsedTruckStockList() : getStockList()),
        {
            enabled: activeTab.value === 'stock',
            onError: (error: Error) => {
                toast.error(error.message || t('message.stock_fetch_fail'))
            },
        },
    )

    const {
        isLoading: stockInfoColumnsLoading,
        data: stockInfoColumnsData,
        refetch: stockInfoColumnsRefetch,
    } = useQuery(['getStockInfoColumn', isUsedTruck, activeTab], () => getStockInfoColumn(), {
        enabled: activeTab.value === 'stock_info',
        onError: (error: Error) => {
            toast.error(error.message || t('message.stock_info_column_fetch_fail'))
        },
    })

    const {
        isLoading: stockInfoLoading,
        data: stockInfoData,
        refetch: stockInfoRefetch,
        isRefetching,
    } = useQuery(
        ['getStockInfo', activeTab, activeTab],
        () => (isUsedTruck ? getAllUsedTruckStockInfo() : getAllStockInfo()),
        {
            enabled: activeTab.value === 'stock_info' && Boolean(stockInfoColumnsData),
            onSuccess: (data: any) => {
                const columnData = stockInfoColumnsData?.data?.data
                const tempStockInfo = data?.data?.data
                setStockInfo(
                    tempStockInfo.map((stk: any) => ({
                        novo_quote_number: stk.novo_quote_number,
                        object_number: stk.object_number,
                        object: stk.object,
                        price: stk.price,
                        type: stk.type,
                        jaar: stk.jaar,
                        serienr: stk.serienr,
                        ontvangst: stk.ontvangst,
                        stock_id: stk.stock_id,
                        ...columnData.reduce((acc: any, c: any) => {
                            acc[c.id] = stk?.details ? JSON.parse(stk?.details)?.[c.id] : null
                            return acc
                        }, {}),
                    })),
                )
            },
            onError: (error: Error) => {
                toast.error(error.message || t('message.stock_info_fetch_fail'))
            },
        },
    )

    const {
        isLoading: usedTruckInfoLoading,
        data: usedTruckInfoData,
        refetch: usedTruckInfoRefetch,
    } = useQuery(['getUsedTruckInfo', activeTab], () => getAllUsedTruckInfo(), {
        enabled: activeTab.value === 'used_truck_details',
        onError: (error: Error) => {
            toast.error(error.message || t('message.stock_info_fetch_fail'))
        },
    })

    const updateStockMutation = useMutation(
        ['update_stock'],
        (params: any) => saveStockInfoByNovoQuoteNumber(params.stock_id, params),
        {
            onError: (e: Error) => {
                toast.error(e.message || t('save_stock_fail'))
            },
        },
    )

    const updateStockColumnMutation = useMutation(
        ['update_stock_column_name'],
        (params: { id: number; name: string }) => saveStockInfoColumnNameById(params),
        {
            onError: (e: Error) => {
                toast.error(e.message || t('save_stock_fail'))
            },
        },
    )

    const exportUsedTruckInfo = useMutation(
        ['exportUsedTruckInfo'],
        () => downloadUsedTruckInfo(),
        {
            onSuccess: (d: any) => {
                const contentDisposition = d.headers['content-disposition']
                const filename =
                    contentDisposition?.split('filename=')[1]?.replace(/["']/g, '') ||
                    'used-truck-info.xlsx'
                const url = window.URL.createObjectURL(new Blob([d?.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(url)
            },
            onError: (error: { message: string }) => {
                toast.error(error?.message || t('message.export_used_truck_info_fail'))
            },
        },
    )

    const location = useLocation()
    useEffect(() => {
        if (location?.pathname) {
            setIsUsedTruck(location.pathname.includes(USED_TRUCK_ROUTE))
        }
        if (location?.state?.activeTab) {
            setActiveTab(location.state.activeTab)
        }
    }, location.state)

    const configurationFuse = new Fuse(configurationData ? configurationData?.data?.data : [], {
        shouldSort: true,
        threshold: 0.3,
        includeScore: true,
        keys: [
            'description',
            'created_by.name',
            'updated_by.name',
            'novo_quote_number',
            'part_number',
            'model',
            {
                name: 'object_number',
                getFn: (item: any) => item.object_number,
                weight: 1,
            },
        ],
    })

    const results = configurationFuse.search(query)
    const novoConfigurationResults = query
        ? results.map(novoConfiguration => novoConfiguration.item)
        : configurationData?.data?.data

    const getFilterData = () => {
        let tempDataList = novoConfigurationResults
        if (status !== 'all' && status === 'lithium_battery') {
            tempDataList = tempDataList.filter(
                (item: { lithium_battery: boolean }) => item.lithium_battery,
            )
        }
        if (status !== 'all' && status === 'deleted') {
            tempDataList = tempDataList.filter((item: { is_deleted: boolean }) => item.is_deleted)
        }

        if (connectedStatus !== 'all' && connectedStatus === 'connected') {
            tempDataList = tempDataList.filter(
                (item: { quote_id: number }) => item.quote_id !== null,
            )
        }

        if (connectedStatus !== 'all' && connectedStatus === 'not-connected') {
            tempDataList = tempDataList?.filter(
                (item: { quote_id: number }) => item.quote_id === null || !item.quote_id,
            )
        }

        return orderBy(tempDataList, ['id'], ['desc'])
    }

    const stockFuse = new Fuse(stockData ? stockData?.data?.data : [], {
        shouldSort: true,
        threshold: 0.3,
        keys: [
            'description',
            'created_by.name',
            'updated_by.name',
            'novo_quote_number',
            'part_number',
            'model',
        ],
    })

    const stockResults = stockFuse.search(query)
    const stockFinalResults = query
        ? stockResults.map(novoConfiguration => novoConfiguration.item)
        : stockData?.data?.data

    const stockInfoFuse = new Fuse(stockInfo, {
        shouldSort: true,
        threshold: 0.3,
        keys: ['stock_id', 'novo_quote_number', 'object', 'type', 'jaar', 'serienr', 'ontvangst'],
    })

    const stockInfoResult = stockInfoFuse.search(query)
    const stockFinalResult = query ? stockInfoResult.map(n => n.item) : stockInfo

    const useTruckInfoFuse = new Fuse(usedTruckInfoData ? usedTruckInfoData?.data?.data : [], {
        shouldSort: true,
        threshold: 0.0,
        keys: [
            'id',
            'type',
            {
                name: 'object_number',
                getFn: (item: any) => item.object_number,
                weight: 1,
            },
            'series_number',
            'merk',
        ],
        useExtendedSearch: true,
    })

    const usedTruckInfoResult = useTruckInfoFuse.search(query)
    const usedTruckInfoFinalResult = query
        ? usedTruckInfoResult.map(n => n.item)
        : usedTruckInfoData?.data?.data

    if (
        configurationLoading ||
        stockLoading ||
        stockInfoLoading ||
        isRefetching ||
        usedTruckInfoLoading
    ) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    const handleStockInfoUpdate = (
        value: string,
        stockId: string,
        type: 'object' | 'price' | 'type' | 'jaar' | 'serienr' | 'ontvangst' | 'details',
        columnId?: number,
    ) => {
        const tempStockInfo = stockInfo
        const index = tempStockInfo.findIndex((item: any) => item.stock_id === stockId)
        switch (type) {
            case 'object':
                tempStockInfo[index].object = value
                break
            case 'price':
                tempStockInfo[index].price = value
                break
            case 'type':
                tempStockInfo[index].type = value
                break
            case 'jaar':
                tempStockInfo[index].jaar = value
                break
            case 'serienr':
                tempStockInfo[index].serienr = value
                break
            case 'ontvangst':
                tempStockInfo[index].ontvangst = value
                break
            case 'details':
                if (columnId) {
                    tempStockInfo[index][columnId] = value
                }
                break
            default:
                break
        }
        updateStockMutation.mutate({
            ...tempStockInfo[index],
            details: stockInfoColumnsData?.data?.data?.reduce((acc: any, item: any) => {
                // Ensure that we are getting the correct value for the item based on `id`
                acc[item.id] = tempStockInfo[index][item.id] || null // If no value, set to null
                return acc
            }, {}),
        })
        setStockInfo([...tempStockInfo])
    }

    const stockInfoColumn = [
        {
            title: t('api.stock_id'),
            dataIndex: 'stock_id',
            key: 'stock_id',
            fixed: 'left' as any,
            width: 80,
        },
        !isUsedTruck
            ? {
                  title: t('api.quote_number'),
                  dataIndex: 'novo_quote_number',
                  key: 'novo_quote_number',
                  fixed: 'left' as any,
                  width: 150,
              }
            : {
                  title: t('api.object_number'),
                  dataIndex: 'object_number',
                  key: 'object_number',
                  fixed: 'left' as any,
                  width: 150,
              },
        {
            title: t('api.object'),
            dataIndex: 'object',
            key: 'object',
            width: 120,
            render: (text: string, record: any) =>
                isUserAuthoriazed ? (
                    <AInput
                        name="object"
                        key={`${record.stock_id} ${record.object}`}
                        defaultValue={text}
                        onBlur={e =>
                            handleStockInfoUpdate(e.target.value, record.stock_id, 'object')
                        }
                    />
                ) : (
                    text
                ),
        },
        {
            title: t('api.price'),
            dataIndex: 'price',
            key: 'price',
            width: 120,
            render: (text: string, record: any) =>
                isUserAuthoriazed ? (
                    <AInput
                        name="price"
                        key={`${record.stock_id} ${record.price}`}
                        type="number"
                        className="text-right"
                        defaultValue={text}
                        prefix={getCurrencySymbol()}
                        onBlur={e => {
                            handleStockInfoUpdate(e.target.value, record.stock_id, 'price')
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: t('api.type'),
            dataIndex: 'type',
            key: 'type',
            width: 280,
            render: (text: string, record: any) =>
                isUserAuthoriazed ? (
                    <AInput
                        name="type"
                        defaultValue={text}
                        key={`${record.stock_id} ${record.type}`}
                        className="text-sm"
                        onBlur={e => {
                            handleStockInfoUpdate(e.target.value, record.stock_id, 'type')
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: t('api.jaar'),
            dataIndex: 'jaar',
            key: 'jaar',
            width: 120,
            render: (text: string, record: any) =>
                isUserAuthoriazed ? (
                    <AInput
                        name="jaar"
                        defaultValue={text}
                        key={`${record.stock_id} ${record.jaar}`}
                        onBlur={e => {
                            handleStockInfoUpdate(e.target.value, record.stock_id, 'jaar')
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: t('api.serienr'),
            dataIndex: 'serienr',
            key: 'serienr',
            width: 120,
            render: (text: string, record: any) =>
                isUserAuthoriazed ? (
                    <AInput
                        name="serienr"
                        defaultValue={text}
                        key={`${record.stock_id} ${record.serienr}`}
                        onBlur={e => {
                            handleStockInfoUpdate(e.target.value, record.stock_id, 'serienr')
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: t('api.ontvangst'),
            dataIndex: 'ontvangst',
            key: 'ontvangst',
            width: 120,
            render: (text: string, record: any) =>
                isUserAuthoriazed ? (
                    <AInput
                        name="ontvangst"
                        defaultValue={text}
                        key={`${record.stock_id} ${record.ontvangst}`}
                        onBlur={e => {
                            handleStockInfoUpdate(e.target.value, record.stock_id, 'ontvangst')
                        }}
                    />
                ) : (
                    text
                ),
        },
        ...(stockInfoColumnsData?.data?.data
            ? stockInfoColumnsData?.data?.data?.map((c: any) => ({
                  title: isUserAuthoriazed ? (
                      <AInput
                          name={c.id}
                          defaultValue={c.name}
                          key={`${c.id}`}
                          onBlur={e =>
                              updateStockColumnMutation.mutate({
                                  id: c.id,
                                  name: e.target.value,
                              })
                          }
                      />
                  ) : (
                      c.name
                  ),
                  dataIndex: c.id,
                  key: c.id,
                  width: 280,
                  render: (text: string, record: any) =>
                      isUserAuthoriazed ? (
                          <AInput
                              name={c.id}
                              defaultValue={text}
                              key={`${record.stock_id} ${c.id}`}
                              onBlur={e => {
                                  handleStockInfoUpdate(
                                      e.target.value,
                                      record.stock_id,
                                      'details',
                                      c.id,
                                  )
                              }}
                          />
                      ) : (
                          text
                      ),
              }))
            : []),
    ]

    const usedTruckinfoColumn = [
        {
            title: t('api.id'),
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: t('api.type'),
            dataIndex: 'type',
            key: 'type',
            width: 120,
        },
        {
            title: t('api.object_number'),
            dataIndex: 'object_number',
            key: 'object_number',
            width: 120,
        },
        {
            title: t('api.series_number'),
            dataIndex: 'series_number',
            key: 'series_number',
            width: 120,
        },
        {
            title: t('api.merk'),
            dataIndex: 'merk',
            key: 'merk',
            width: 120,
        },
        {
            title: t('api.brandstof'),
            dataIndex: 'brandstof',
            key: 'brandstof',
            width: 120,
        },
        {
            title: t('api.jaar'),
            dataIndex: 'jaar',
            key: 'jaar',
            width: 60,
        },
        {
            title: t('api.uren'),
            dataIndex: 'uren',
            key: 'uren',
            width: 60,
        },
        {
            title: t('api.hefhoogte'),
            dataIndex: 'hefhoogte',
            key: 'hefhoogte',
            width: 80,
        },
        {
            title: t('api.type_mast'),
            dataIndex: 'type_mast',
            key: 'type_mast',
            width: 80,
        },
        {
            title: t('api.doorrijhoogte'),
            dataIndex: 'doorrijhoogte',
            key: 'doorrijhoogte',
            width: 80,
        },
        {
            title: t('api.transm'),
            dataIndex: 'transm',
            key: 'transm',
            width: 120,
        },
        {
            title: t('api.functies'),
            dataIndex: 'functies',
            key: 'functies',
            width: 120,
        },
        {
            title: t('api.mini_mech_handel'),
            dataIndex: 'mini_mech_handel',
            key: 'mini_mech_handel',
            width: 120,
        },
        {
            title: t('api.voorzetapp'),
            dataIndex: 'voorzetapp',
            key: 'voorzetapp',
            width: 240,
        },
        {
            title: t('api.batterij'),
            dataIndex: 'batterij',
            key: 'batterij',
            width: 240,
        },
        {
            title: t('api.cabine'),
            dataIndex: 'cabine',
            key: 'cabine',
            width: 120,
        },
        {
            title: t('api.werklampen'),
            dataIndex: 'werklampen',
            key: 'werklampen',
            width: 120,
        },
        {
            title: t('api.verlichting'),
            dataIndex: 'verlichting',
            key: 'verlichting',
            width: 120,
        },
        {
            title: t('api.bijzonderheden'),
            dataIndex: 'bijzonderheden',
            key: 'bijzonderheden',
            width: 240,
        },
        {
            title: t('api.oude_price'),
            dataIndex: 'oude_price',
            key: 'oude_price',
            width: 120,
            render: (text: string) => <p className="text-right">{formatCurrency(text)}</p>,
        },
        {
            title: t('api.gold'),
            dataIndex: 'gold',
            key: 'gold',
            width: 120,
            render: (text: string) => <p className="text-right">{formatCurrency(text)}</p>,
        },
        {
            title: t('api.silver'),
            dataIndex: 'silver',
            key: 'silver',
            width: 120,
            render: (text: string) => <p className="text-right">{formatCurrency(text)}</p>,
        },
        {
            title: t('api.bronze'),
            dataIndex: 'bronze',
            key: 'bronze',
            width: 120,
            render: (text: string) => <p className="text-right">{formatCurrency(text)}</p>,
        },
        {
            title: t('api.actions'),
            dataIndex: 'object_number',
            key: 'object_number',
            width: 120,
            render: (object_number: string) => (
                <UsedTruckListAction objectNumber={object_number} refetch={usedTruckInfoRefetch} />
            ),
        },
    ]

    return (
        <div className={classNames(cardClass)}>
            <Tabs
                tabs={tabs || []}
                getActiveTab={(tab: TabType) => {
                    setActiveTab(tab)
                }}
                tabActive={activeTab}
                leftContent={
                    <div className="flex gap-4 justify-end items-center">
                        <div className="flex flex-wrap md:justify-center justify-end items-center md:flex-nowrap gap-4">
                            {(updateStockColumnMutation.isLoading ||
                                updateStockMutation.isLoading) && (
                                <p className="flex gap-2 text-sm items-center animate-pulse">
                                    <IoCloudUpload size={16} />
                                    <span>{t('api.saving')}</span>
                                </p>
                            )}
                            <Input
                                placeholder={t('api.search')}
                                name="search"
                                type="text"
                                leftContent={<BiSearch />}
                                value={query}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setSearchQuery(e.target.value)
                                }}
                            />
                            {/* <Select
                                value="all"
                                placeholder={`${t('api.select')} ${t('api.status')}`}
                                size="large"
                                className="rounded-md w-fit"
                                onChange={onChangeStatus}
                            >
                                {[
                                    {
                                        value: 'all',
                                        label: t('api.all'),
                                    },
                                    {
                                        value: 'lithium_battery',
                                        label: t('api.lithium_battery'),
                                    },
                                    {
                                        value: 'deleted',
                                        label: t('api.deleted'),
                                    },
                                ].map(item => (
                                    <Select.Option value={item.value}>{item.label}</Select.Option>
                                ))}
                            </Select> */}
                            {activeTab.value === 'novo_configuration' ||
                                (activeTab.value === 'used_truck_configuration' && (
                                    <Select
                                        value={connectedStatus}
                                        style={{ width: 240 }}
                                        size="large"
                                        className="rounded-md"
                                        onChange={value => setConnectedStatus(value)}
                                    >
                                        {[
                                            {
                                                value: 'all',
                                                label: t('api.all'),
                                            },
                                            {
                                                value: 'connected',
                                                label: t('api.connected'),
                                            },
                                            {
                                                value: 'not-connected',
                                                label: t('api.not_connected'),
                                            },
                                        ].map(item => (
                                            <Select.Option value={item.value}>
                                                {item.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ))}
                            {(isUsedTruck
                                ? (userExtra.role_id === ROLE_TYPE.sales_support ||
                                      userExtra.role_id === ROLE_TYPE.sales_manager ||
                                      userExtra.role_id === ROLE_TYPE.used_truck_support) &&
                                  activeTab.value === 'used_truck_configuration'
                                : true) && (
                                <Link
                                    to={
                                        isUsedTruck
                                            ? `${USED_TRUCK_ROUTE}/import`
                                            : '/novo-configuration/import'
                                    }
                                >
                                    <Button
                                        id="file_submit"
                                        label={
                                            isUsedTruck
                                                ? t('api.upload_used_truck_configuration')
                                                : t('api.upload_novo_configuration')
                                        }
                                        variant="outline"
                                        type="button"
                                    />
                                </Link>
                            )}
                            {activeTab.value !== 'used_truck_details' &&
                                (userExtra.role_id === ROLE_TYPE.sales_support ||
                                    userExtra.role_id === ROLE_TYPE.sales_manager) &&
                                !isUsedTruck && (
                                    <Link
                                        to={
                                            isUsedTruck
                                                ? `${USED_TRUCK_ROUTE}/import-stock`
                                                : '/novo-configuration/import-stock'
                                        }
                                    >
                                        <Button
                                            id="file_submit"
                                            label={
                                                isUsedTruck
                                                    ? t('api.upload_used_truck_stock')
                                                    : t('api.upload_stock')
                                            }
                                            variant="outline"
                                            type="button"
                                        />
                                    </Link>
                                )}
                            {activeTab.value === 'used_truck_details' &&
                                (userExtra.role_id === ROLE_TYPE.sales_support ||
                                    userExtra.role_id === ROLE_TYPE.sales_manager ||
                                    userExtra.role_id === ROLE_TYPE.used_truck_support) && (
                                    <Link to={`${USED_TRUCK_ROUTE}/import?used-truck-info=true`}>
                                        <Button
                                            id="import_used_truck_info"
                                            label={t('api.import_used_truck_info')}
                                            variant="outline"
                                            type="button"
                                        />
                                    </Link>
                                )}
                            {activeTab.value === 'used_truck_details' && (
                                <Button
                                    id="export"
                                    isLoading={exportUsedTruckInfo.isLoading}
                                    label={t('api.export_used_truck_info')}
                                    variant="outline"
                                    type="button"
                                    onClick={() => exportUsedTruckInfo.mutate()}
                                />
                            )}
                        </div>
                    </div>
                }
            />
            <div className="border rounded-md border-gray overflow-auto">
                {(activeTab.value === 'novo_configuration' ||
                    activeTab.value === 'used_truck_configuration') && (
                    <ExcelTable
                        excelData={getFilterData() || []}
                        isLoading={false}
                        isUsedTruck={isUsedTruck}
                        refetch={configurationRefetch}
                    />
                )}
                {activeTab.value === 'stock' && (
                    <ExcelTable
                        excelData={stockFinalResults || []}
                        isLoading={false}
                        showStockQty
                        isUsedTruck={isUsedTruck}
                        refetch={stockRefetch}
                    />
                )}
                {activeTab.value === 'stock_info' && (
                    <Table
                        bordered
                        dataSource={stockFinalResult}
                        columns={stockInfoColumn}
                        scroll={{ x: 3200 }}
                        style={{
                            padding: '4px',
                        }}
                        pagination={pagination}
                        onChange={(p: TablePaginationConfig) => {
                            setPagination(p)
                            stockInfoRefetch()
                        }}
                    />
                )}
                {activeTab.value === 'used_truck_details' && (
                    <Table
                        bordered
                        dataSource={usedTruckInfoFinalResult}
                        columns={usedTruckinfoColumn}
                        scroll={{ x: 3200 }}
                        style={{
                            padding: '4px',
                        }}
                        pagination={pagination}
                        onChange={(p: TablePaginationConfig) => {
                            setPagination(p)
                            usedTruckInfoRefetch()
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default NovoConfiguration
