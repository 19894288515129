import AlertModal from 'components/modal/alertModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillDelete, AiOutlineEye, AiOutlineHistory } from 'react-icons/ai'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { NOVO_CONFIGURATION_ROUTE, USED_TRUCK_ROUTE } from 'route/appRoutes'
import { deleteNovoConfiguration, deleteStock, deleteUsedTruckInfoByObjectNumber } from '../query'

interface ColumnListActionProps {
    configuration: number
    refetch?: () => void
    isStock?: boolean
    isUsedTruck?: boolean
    objectNumber?: string
}

const ColumnListAction = (props: ColumnListActionProps) => {
    const { t } = useTranslation()
    const { configuration, refetch, isStock, isUsedTruck, objectNumber } = props
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showDeleteUsedTruckInfo, setShowDeleteUsedTruckInfo] = useState<boolean>(false)

    const deleteNovoConfigurationMutation = useMutation(
        ['deleteNovoConfiguration'],
        () =>
            isStock
                ? deleteStock(String(configuration))
                : deleteNovoConfiguration(String(configuration)),
        {
            onSuccess: () => {
                toast.success(`${t('message.delete_novo_configuration_success')}`)
                if (isUsedTruck) {
                    setShowDeleteUsedTruckInfo(true)
                } else if (refetch) {
                    refetch()
                }
                setShowDeleteModal(false)
            },
            onError: (error: { message: string }) => {
                toast.error(error?.message || `${t('message.delete_novo_configuration_failed')}`)
            },
        },
    )

    const deleteUsedTruckInfoByObjectNumberMutation = useMutation(
        ['deleteUsedTruckInfoByObjectNumber'],
        () => deleteUsedTruckInfoByObjectNumber(String(objectNumber)),
        {
            onSuccess: () => {
                toast.success(`${t('message.delete_object_number_success')}`)
                if (refetch) {
                    refetch()
                }
                setShowDeleteUsedTruckInfo(false)
            },
            onError: (error: { message: string }) => {
                if (refetch) {
                    refetch()
                }
                setShowDeleteUsedTruckInfo(false)
                toast.error(error?.message || `${t('message.delete_object_number_failed')}`)
            },
        },
    )

    const route = isUsedTruck ? USED_TRUCK_ROUTE : NOVO_CONFIGURATION_ROUTE

    return (
        <div className="flex items-center justify-center gap-2">
            <a
                href={isStock ? `${route}/stock/${configuration}` : `${route}/${configuration}`}
                title={t('api.view_novo_configuration_detail')}
            >
                <AiOutlineEye size={25} />
            </a>
            {!isStock && (
                <a
                    href={`${route}/history/${configuration}`}
                    title={t('api.history_novo_configuration')}
                >
                    <AiOutlineHistory size={20} />
                </a>
            )}
            <button
                type="button"
                onClick={() => {
                    setShowDeleteModal(true)
                }}
            >
                <AiFillDelete size={20} />
            </button>
            <AlertModal
                show={showDeleteModal}
                isSubmitting={deleteNovoConfigurationMutation.isLoading}
                onCancel={() => setShowDeleteModal(!showDeleteModal)}
                onConfirm={() => deleteNovoConfigurationMutation.mutate()}
                content={
                    <p>
                        {isUsedTruck
                            ? t('message.delete_used_truck_configuration')
                            : t('message.delete_novo_configuration')}
                    </p>
                }
            />
            <AlertModal
                show={showDeleteUsedTruckInfo}
                isSubmitting={deleteUsedTruckInfoByObjectNumberMutation.isLoading}
                onCancel={() => setShowDeleteUsedTruckInfo(!showDeleteUsedTruckInfo)}
                onConfirm={() => deleteUsedTruckInfoByObjectNumberMutation.mutate()}
                content={<p>{t('message.delete_used_truck_info')}</p>}
            />
        </div>
    )
}

export default ColumnListAction
