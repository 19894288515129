import { callAxios } from 'plugins/api'

export interface IStockInfo {
    novo_quote_number: string
    object: string
    price: string
    type: string
    jaar: string
    serienr: string
    ontvangst: string
    details: string
}

export const parseExcelData = (file: any, isUsedTruck: boolean) => {
    const formData = new FormData()
    formData.append('excel_file', file)
    formData.append('is_used_truck', String(isUsedTruck))
    return callAxios({
        url: 'novo/file-upload',
        method: 'POST',
        data: formData,
        isHeffiq: true,
    })
}

export const importStock = (file: any, isUsedTruck: boolean) => {
    const formData = new FormData()
    formData.append('excel_file', file)
    formData.append('is_used_truck', String(isUsedTruck))
    return callAxios({
        url: 'novo/import-stock',
        method: 'POST',
        data: formData,
        isHeffiq: true,
    })
}

export const importUsedTruckInfo = (file: any) => {
    const formData = new FormData()
    formData.append('excel_file', file)
    return callAxios({
        url: 'used-truck-info/import',
        method: 'POST',
        data: formData,
        isHeffiq: true,
    })
}

export const getNovoConfigurationById = (configurationId: number) => {
    return callAxios({
        url: `novo/configurations/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getNovoConfigurationListByUser = () => {
    return callAxios({
        url: 'novo/my-configurations',
        method: 'GET',
        isHeffiq: true,
    })
}

export const getNovoUsedConfigurationListByUser = () => {
    return callAxios({
        url: 'novo/my-configurations/used-truck',
        method: 'GET',
        isHeffiq: true,
    })
}

export const getStockList = () => {
    return callAxios({
        url: 'novo/stocks',
        method: 'GET',
        isHeffiq: true,
    })
}

export const getUsedTruckStockList = () => {
    return callAxios({
        url: 'novo/stocks/used-truck',
        method: 'GET',
        isHeffiq: true,
    })
}

export const updateNovoConfiguration = (configurationId: string, data: any) => {
    return callAxios({
        url: `novo/configurations/data/${configurationId}`,
        method: 'PUT',
        data,
        isHeffiq: true,
    })
}

export const getNovoConfigurationHistory = (configurationId: string) => {
    return callAxios({
        url: `novo/configurations/${configurationId}/versions`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getNovoConfigurationVersionDetail = (versionId: number, configurationId: number) => {
    return callAxios({
        url: `novo/configurations/${versionId}/versions/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getConnectSiteSurvey = (configurationId: string) => {
    return callAxios({
        url: `siteSurvey/connected/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getAllUnattachedSiteSurvey = (configurationId: string) => {
    return callAxios({
        url: `siteSurvey/match-configuration/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getConfigurationImage = (configurationId: number) => {
    return callAxios({
        url: `novo/configurations/${configurationId}/images`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const deleteNovoConfiguration = (configurationId: string) => {
    return callAxios({
        url: `novo/configurations/${configurationId}`,
        method: 'DELETE',
        isHeffiq: true,
    })
}

export const deleteNovoConfigurationByObjectNumber = (objectNumber: string) => {
    return callAxios({
        url: `novo/configurations/object-number/${objectNumber}`,
        method: 'DELETE',
        isHeffiq: true,
    })
}

export const getStockDetailById = (stockId: number) => {
    return callAxios({
        url: `novo/stocks/reservation/${stockId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const deleteStock = (stockId: string) => {
    return callAxios({
        url: `novo/stocks/${stockId}`,
        method: 'DELETE',
        isHeffiq: true,
    })
}

export const deleteUsedTruckInfoByObjectNumber = (objectNumber: string) =>
    callAxios({
        url: `used-truck-info/${objectNumber}`,
        method: 'DELETE',
        isHeffiq: true,
    })

export type AttchedSiteSurvey = {
    site_survey_id: number | null
    is_novo: boolean
}

export const attachSiteSurvey = (configurationId: string, data: AttchedSiteSurvey) => {
    return callAxios({
        url: `novo/configurations/${configurationId}/connect-site-survey`,
        method: 'PUT',
        data,
        isHeffiq: true,
    })
}

export const unreserveStock = (configurationId: number) => {
    return callAxios({
        url: `novo/stocks/configuration/unreserve/${configurationId}`,
        method: 'POST',
        isHeffiq: true,
    })
}

export const reserveStock = (configurationId: number) => {
    return callAxios({
        url: `novo/stocks/configuration/reserve/${configurationId}`,
        method: 'POST',
        isHeffiq: true,
    })
}

export const getUsedTruckReservationDetail = (objectNumber: string) => {
    return callAxios({
        url: `novo/used-truck/reservation/${objectNumber}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const reserveUsedTruck = (configurationId: number) => {
    return callAxios({
        url: `novo/used-truck/configuration/reserve/${configurationId}`,
        method: 'POST',
        isHeffiq: true,
    })
}

export const unReserveUsedTruck = (configurationId: number) => {
    return callAxios({
        url: `novo/used-truck/configuration/unreserve/${configurationId}`,
        method: 'POST',
        isHeffiq: true,
    })
}

export const getAllStockInfo = () =>
    callAxios({
        url: `stock-info`,
        method: 'GET',
        isHeffiq: true,
    })

export const getAllUsedTruckStockInfo = () =>
    callAxios({
        url: `stock-info/used-truck`,
        method: 'GET',
        isHeffiq: true,
    })

export const getStockInfoColumn = () =>
    callAxios({
        url: 'stock-info/stock-info-columns',
        method: 'GET',
        isHeffiq: true,
    })

export const getStockById = (stockId: number) =>
    callAxios({
        url: `novo/stocks/${stockId}`,
        method: 'GET',
        isHeffiq: true,
    })

export const saveStockInfoByNovoQuoteNumber = (stockId: number, stockInfo: IStockInfo) =>
    callAxios({
        url: `stock-info/${stockId}`,
        method: 'POST',
        data: stockInfo,
        isHeffiq: true,
    })

export const saveStockInfoColumnNameById = (params: { id: number; name: string }) =>
    callAxios({
        url: `stock-info/stock-info-columns/${params.id}`,
        method: 'POST',
        data: params,
        isHeffiq: true,
    })

export const getAllUsedTruckInfo = () =>
    callAxios({
        url: 'used-truck-info',
        method: 'GET',
        isHeffiq: true,
    })

export const downloadUsedTruckInfo = () =>
    callAxios({
        url: 'used-truck-info/export',
        method: 'GET',
        isHeffiq: true,
        responseType: 'blob',
    })
