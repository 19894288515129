/* eslint-disable camelcase */
import exp from 'constants'
import { StringMap } from 'i18next'
import { extend, map } from 'lodash'
import { ROLE_TYPE } from 'modules/auth/context/useAuth'
import { callAxios } from 'plugins/api'

// const user = JSON.parse(localStorage.getItem('CPQ_LOGGED_IN_USER') || '{}')
const userInfo = JSON.parse(localStorage.getItem('CPQ_LOGGED_IN_USER_EXTRA_INFO') || '{}')

// const getApi = () => {
//     const role: UserRole = getUserRole()
//     switch (role) {
//         case 'sales-support':
//             return 'sales-support/'
//         default:
//             return 'quote/user'
//     }
// }

export interface ICreateQuote {
    configurations: any
    stocks: any
    usedTrucks: any
    quotation_for: string
    quotation_name: string
    user?: string | number
    connection_type?: 'configurations' | 'stock'
    status?: string
    companyName?: string
    firstName?: string
    familyName?: string
    maleOrFemale?: 'male' | 'female'
    address1?: string
    address2?: string
    postalCode?: string
    city?: string
    department?: string
}

export const getAllQuotes = (userId: string | number, isFleet: boolean, isSupport?: boolean) => {
    if (isFleet) {
        return callAxios({
            url: `quote`,
            method: 'GET',
        })
    }
    if (isSupport) {
        return callAxios({
            url: `sales-support/getAllQuotesSalesSupport/${userId || userId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `quote`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getFleetQuote = () => {
    return callAxios({
        url: `v2/quote/request-fleet`,
        method: 'GET',
    })
}

export const getWorkshopQuotes = () =>
    callAxios({
        url: `quote/request-workshop`,
        method: 'GET',
        isHeffiq: true,
    })

export const updateOrderOpportunity = (id: string, value: string | number) => {
    return callAxios({
        url: `quote/editOrderOpportunity/${id}`,
        method: 'POST',
        data: {
            order_opportunity: value,
        },
    })
}

export const updateExpectedDiscount = (id: string, value: string) => {
    return callAxios({
        url: `quote/editExpectedDeliveryDate/${id}`,
        method: 'POST',
        data: {
            expected_delivery_date: value,
        },
    })
}

export const updateQuoteStatus = (id: string, formData: any, userId: number) => {
    return callAxios({
        url: `quote/changeStatusOfQuote/${userId}/${id}`,
        method: 'POST',
        data: {
            custom_comment: formData.custom_comment,
            generic_id: formData.generic_id,
            status: formData.status,
        },
    })
}

export const updateQuoteTemplate = (id: string, value: string) => {
    return callAxios({
        url: `quote/updateTemplateForQuote/${id}`,
        method: 'POST',
        data: {
            template_id: value,
        },
    })
}

export const getExtraByQuoteId = (quoteId: string) =>
    callAxios({
        url: `extraItem/by-quote/${quoteId}`,
        method: 'GET',
        isHeffiq: true,
    })

export const orderQuoteData = (formData: any, userId: number) => {
    return callAxios({
        url: `order/${userId}`,
        method: 'POST',
        data: {
            configuration_id: formData.configuration_id,
            order_name: `${formData.quotation_name}-order`,
            quote_id: formData.quote_id,
        },
    })
}

export const createQuote = (formData: ICreateQuote, userId: number) => {
    // eslint-disable-next-line no-param-reassign
    formData.configurations = formData.configurations.map((c: number) => {
        return {
            value: c,
        }
    })
    // eslint-disable-next-line no-param-reassign
    formData.stocks = formData.stocks.map((c: number) => {
        return {
            value: c,
        }
    })

    // eslint-disable-next-line no-param-reassign
    formData.usedTrucks = formData.usedTrucks.map((c: number) => {
        return {
            value: c,
        }
    })

    return callAxios({
        url: `quote`,
        method: 'POST',
        data: {
            ...formData,
            user_id: userId,
        },
        isHeffiq: true,
    })
}

export const deleteQuote = (quoteId: string) => {
    return callAxios({
        url: `v2/quote/${quoteId}`,
        method: 'DELETE',
    })
}

export const editQuote = (quoteId: number | string, param: any) => {
    return callAxios({
        url: `quote/edit/${quoteId}`,
        data: param,
        method: 'POST',
    })
}

export const approveQuote = (quoteId: string, userId: number) => {
    return callAxios({
        url: `quote/approvedQuoteNotification/${quoteId}/${userId}`,
        method: 'POST',
    })
}

export const requestQuoteApproval = (quoteId: number, userId: number) => {
    return callAxios({
        url: `quote/requestApprovalNotification/${quoteId}/${userId}`,
        method: 'POST',
    })
}

export const orderQuote = (quoteId: string) => {
    return callAxios({
        url: `quote/checkQuoteOrdered/${quoteId}`,
        method: 'GET',
    })
}

export const getQuoteDetail = (quoteId: string, userId: number, isSupport?: boolean) => {
    if (isSupport) {
        return callAxios({
            url: `sales-support/getSingleQuoteSalesSupport/${userId}/${quoteId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `quote/${quoteId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

// templatee
export const getAllSelectedTemplate = (role: string | number) => {
    return callAxios({
        url: `template/getCurrentTemplatesForRole/${role || userInfo.role_id}`,
        method: 'GET',
    })
}

export const getAllConfigurationRemaining = () => {
    return callAxios({
        url: `configuration/getUserConfigurationsForMultiselect`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getAllRemainingStock = () => {
    return callAxios({
        url: `configuration/getRemainingStockConfiguration`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getAllRemainingUsedTruck = () => {
    return callAxios({
        url: `configuration/get-remaining-used-truck`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getAllNovoConfigurationRemaining = () => {
    return callAxios({
        url: `configuration/getUserConfigurationsForMultiselect/novo`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getGenericQuestion = () => {
    return callAxios({
        url: `generic-question`,
        method: 'GET',
    })
}

export const getTeamsQuotes = (userId: number) => {
    return callAxios({
        url: `user-hierarchy/showChildrenQuotes/${userId}`,
        method: 'GET',
    })
}

export const updateQuoteOwnership = (payload: { user_id: number; quote_id: number }) => {
    return callAxios({
        url: `quote/update-user/${payload.quote_id}`,
        data: { user_id: payload.user_id },
        method: 'POST',
        isHeffiq: true,
    })
}

export const requestMultipleDiscountRequest = (discountRequestData: any, userId: number) => {
    return callAxios({
        url: `discount-notification/QuotesDiscount/${userId}`,
        method: 'POST',
        data: discountRequestData,
    })
}

export const getAllConfigurationForQuote = (userId: number) => {
    return callAxios({
        url: `configuration/getUserConfigurationsForMultiselect/${userId}`,
        method: 'GET',
    })
}

export const getAllTemplateForQuote = () => {
    return callAxios({
        url: `template/getCurrentTemplatesForRole/${userInfo.role_id}`,
        method: 'GET',
    })
}

export const addMoreConfigurationOnQuote = (configurationQuoteDetail: any, userId: number) => {
    return callAxios({
        url: `quote/edit/${configurationQuoteDetail.quote_id}`,
        method: 'POST',
        data: {
            ...configurationQuoteDetail,
            user_id: userId,
        },
    })
}

export const addMoreNovoConfigurationOnQuote = (params: any, quoteId: number) => {
    return callAxios({
        url: `v2/quote/${quoteId}/add-novo-configuration`,
        method: 'PUT',
        data: {
            ...params,
        },
    })
}

export const removeConfigurationFromQuotes = ({
    quoteId,
    configurationId,
    isNovo,
}: {
    quoteId: any
    configurationId: string
    isNovo: boolean
}) => {
    if (isNovo) {
        return callAxios({
            url: `quote/${quoteId}/configuration/${configurationId}/novo`,
            method: 'DELETE',
            isHeffiq: true,
        })
    }
    return callAxios({
        url: `quote/${quoteId}/configuration/${configurationId}`,
        method: 'DELETE',
        isHeffiq: true,
    })
}

export const getProjectOverview = (quoteId: string | number) => {
    return callAxios({
        url: `quote/${quoteId}/project-overview`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getChargerListByModel = (configurationId: string | number) => {
    return callAxios({
        url: `v2/charger/${configurationId}`,
        method: 'GET',
    })
}
export const getBatteryInformationForCharger = (configurationId: string | number) => {
    return callAxios({
        url: `v2/charger/${configurationId}/novo`,
        method: 'GET',
    })
}

export type ExportItemType =
    | 'charger'
    | 'battery'
    | 'preplist'
    | 'battery-accessories'
    | 'hyster battery list'

export const exportChargerData = async (type: ExportItemType) => {
    const res = await callAxios({
        url: `v2/${type}/export`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
    })
    const bolbFile = new Blob([res.data])
    const url = URL.createObjectURL(bolbFile)
    const link = document.createElement('a')
    document.body.appendChild(link)
    link.href = url
    link.setAttribute('download', `${type}-list.csv`)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
    return res?.data
}

export const getBatteryListByModel = (configurationId: string | number) => {
    return callAxios({
        url: `v2/battery/${configurationId}`,
        method: 'GET',
    })
}

export const getPrepListByModel = (configurationId: string | number) => {
    return callAxios({
        url: `v2/preplist/${configurationId}`,
        method: 'GET',
    })
}

export const getBatteryAccessoriesListByModel = () => {
    return callAxios({
        url: `v2/battery-accessories`,
        method: 'GET',
    })
}

export const getHysterBatteryListByModel = (configurationId: string | number) => {
    return callAxios({
        url: `v2/hyster-battery/${configurationId}`,
        method: 'GET',
    })
}

export const getHysterPartNumber = (series_code: string[]) => {
    const series_code_string = String(series_code).replace(/\s/g, '')
    return callAxios({
        url: `v2/hyster-battery/part_number?series_code=${String(series_code_string)}`,
        method: 'GET',
    })
}

export const getQuoteImages = (quoteId: number) => {
    return callAxios({
        url: `quote/series/images/${quoteId}`,
        isHeffiq: true,
        method: 'GET',
    })
}

export const addExtraItem = (configurationId: string, record: any) => {
    return callAxios({
        url: `extraItem/${configurationId}`,
        isHeffiq: true,
        method: 'POST',
        data: record,
    })
}

export const deleteExtraItemById = (extraItemId: number, columnKey: string) => {
    return callAxios({
        url: `extraItem/delete/${extraItemId}`,
        isHeffiq: true,
        method: 'DELETE',
    })
}

export const updateExtraItemById = (extraItemId: number, columnKey: string, value: any) => {
    return callAxios({
        url: `extraItem/update/${extraItemId}`,
        data: value,
        isHeffiq: true,
        method: 'POST',
    })
}

export const deleteManualExtraItemById = (extraItemId: number) => {
    return callAxios({
        url: `extraItem/delete/manual/${extraItemId}`,
        isHeffiq: true,
        method: 'DELETE',
    })
}

export const updateManualExtraItemById = (value: any) => {
    return callAxios({
        url: `extraItem/update/manual/extra`,
        data: value,
        isHeffiq: true,
        method: 'POST',
    })
}

export const addExtraItemForAttachment = (configurationId: string, record: any) => {
    return callAxios({
        url: `extraItem/manual/${configurationId}`,
        isHeffiq: true,
        method: 'POST',
        data: record,
    })
}

export const getConfigurationListByQuoteId = (params: {
    quote_id: string | number
    column: string
    model: string
    series: string
    item_id: number
}) =>
    callAxios({
        url: `quote/show-all-configurations`,
        method: 'POST',
        data: params,
        isHeffiq: true,
    })

export const getExtraItemData = (configurationId: number) => {
    return callAxios({
        url: `extraItem/${configurationId}`,
        isHeffiq: true,
        method: 'GET',
    })
}

export const updateDiscountForConfiguration = (formData: {
    comments: string
    discount_amount: number
    discount_percentage: number
    machine_total_price: number
    quantity: number
    service_rate: number
    trade_in_value: number
    configuration_id: string
}) => {
    return callAxios({
        url: `configuration/updateDiscountForConfiguration/${formData.configuration_id}`,
        method: 'POST',
        data: {
            machine_total_price: formData.machine_total_price,
            discount_amount: formData?.discount_amount || 0,
            discount_percentage: formData?.discount_percentage || 0,
            quantity: formData?.quantity || 0,
            trade_in_value: formData?.trade_in_value || 0,
            service_rate: formData?.service_rate || 0,
            comments: formData?.comments || null,
        },
    })
}

export const downloadProjectOverviewDoc = async (
    quoteID: string | number,
    params: {
        export_array: string[]
        projectOverviewData: any
    },
) => {
    const res = await callAxios({
        url: `quote/exportProjectOverView/${quoteID}`,
        method: 'POST',
        data: {
            export_array: params.export_array,
            projectOverviewData: map(params.projectOverviewData.configurations, item => {
                return extend(item, {
                    currency_symbol_html: 'GBP',
                    discount_total: String(
                        item.discount_amount * item.quantity +
                            (item.discount_percentage *
                                (item.machine_total_price * item.quantity)) /
                                100,
                    ),
                    balance_total: String(
                        item.machine_total_price * item.quantity -
                            item.quantity * item.discount_amount -
                            (item.discount_percentage * item.machine_total_price * item.quantity) /
                                100,
                    ),
                    balance_to_finance:
                        item.machine_total_price * item.quantity -
                        item.quantity * item.discount_amount -
                        (item.discount_percentage * item.machine_total_price * item.quantity) /
                            100 -
                        item.trade_in_value,
                    lease_calculation1: 0,
                    lease_calculation2: 0,
                    lease_calculation3: 0,
                    lease_calculation4: 0,
                })
            }),
        },
    })
    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
        'download',
        `${params.projectOverviewData.quote[0].quotation_name}-${params.projectOverviewData.quote[0].quotation_name}.xlsx`,
    )
    document.body.appendChild(link)
    link.click()
    return 'all_machine_parts.xlsx ready for download'
}

export const downloadImage = async (data: { url: string; name: string }) => {
    const imageUrl = process.env.REACT_APP_S3_IMAGE_URL
    const link = document.createElement('a')
    link.href = `${imageUrl}${data?.url}`
    link.setAttribute('download', `${data?.name || 'heffiq-image'}`)
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
    return 'image downloaded'
}

export const showQuoteDocuments = (quoteId: string) => {
    return callAxios({
        url: `v2/quote/allDocument/${quoteId}`,
        method: 'GET',
    })
}

export const removeQuoteDocument = (quoteId: any, id: number) => {
    return callAxios({
        url: `v2/quote/${quoteId}/document/${id}`,
        method: 'DELETE',
    })
}

export const getApprovalOverview = (quoteId: number) =>
    callAxios({
        url: `quote/${quoteId}/approval-overview`,
        method: 'GET',
        isHeffiq: true,
    })

export const saveQuoteDocument = (quoteId: string, data: any) => {
    return callAxios({
        url: `v2/quote/${quoteId}/document`,
        method: 'POST',
        headers: {
            'content-type': 'multipart/form-data',
        },
        data,
    })
}

export interface IEditQuoteInPricingApproval {
    delivery_cost: number
    contract_cost: number
    debt_collection_fees: number | null
}

export const editQuoteInPricingApproval = (quoteId: number, params: IEditQuoteInPricingApproval) =>
    callAxios({
        url: `quote/${quoteId}`,
        method: 'PUT',
        data: params,
        isHeffiq: true,
    })

export const getQuote = (quoteId: number) =>
    callAxios({
        url: `quote/${quoteId}/detail`,
        method: 'GET',
        isHeffiq: true,
    })

export const getRequestApprovalEmails = () =>
    callAxios({
        url: 'request-approvals/get-email',
        method: 'POST',
        isHeffiq: true,
    })

export const sendRequestApprovalEmails = (params: {
    quote_id: number
    email: string
    name: string
    remark: string
    force_reset_flag: boolean
}) =>
    callAxios({
        url: 'request-approvals/send-email',
        method: 'POST',
        data: params,
        isHeffiq: true,
    })

export const checkApproval = (quoteId: number) =>
    callAxios({
        url: `quote/${quoteId}/check-approval`,
        method: 'GET',
        isHeffiq: true,
    })

export const approveAll = (quoteId: number) =>
    callAxios({
        url: `quote/${quoteId}/approve-all`,
        method: 'PATCH',
        isHeffiq: true,
    })

export const getLeasePriceOptions = (quoteId: number) =>
    callAxios({
        url: `quote/${quoteId}/lease-prices-options`,
        method: 'GET',
        isHeffiq: true,
    })

export const recalculateQuote = (quoteId: number) =>
    callAxios({
        url: `quote/${quoteId}/recalculate`,
        method: 'PATCH',
        isHeffiq: true,
    })

export const reorderConfiguration = (params: { configuration_ids: number[] }) =>
    callAxios({
        url: `quote/reorder`,
        method: 'POST',
        isHeffiq: true,
        data: params,
    })

export const getFreeTextByQuoteId = (quoteId: number) =>
    callAxios({
        url: `free-text/show-by-quote-id/${quoteId}`,
        method: 'GET',
        isHeffiq: true,
    })

export const createFreeText = (params: { quote_id: number; free_text: string }) =>
    callAxios({
        url: `free-text`,
        method: 'POST',
        data: params,
        isHeffiq: true,
    })

export const updateFreeText = (id: number, params: { quote_id: number; free_text: string }) =>
    callAxios({
        url: `free-text/${id}`,
        method: 'PUT',
        data: params,
        isHeffiq: true,
    })

export const deleteFreeText = (id: number) =>
    callAxios({
        url: `free-text/${id}`,
        method: 'DELETE',
        isHeffiq: true,
    })
