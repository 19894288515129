/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames'
import Button from 'components/button'
import { useState } from 'react'
import Modal from 'react-responsive-modal'
import { useNavigate, useParams } from 'react-router-dom'
import { FaLeaf } from 'react-icons/fa'
import { GrFlagFill } from 'react-icons/gr'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import DeleteModal from 'components/modal/deleteModal'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { errorToast, successToast } from 'utils/toasterUtil'

import { PricingQueries } from 'modules/quotes/queries/pricing'
import defaultImage from '../../../assets/heffiq/default.png'
import ConfigurationSiteSurvey from './ConfigurationSiteSurvey'
import { NovoConfigQuote } from '../query/model'
import { deleteNovoConfiguration, getConfigurationImage } from '../query'
import useTranslationData from '../../translation/hooks/useTranslationData'
import Loading from '../../../components/loading'

interface NovoConfgInfoCardProps {
    machine: NovoConfigQuote
    showOptional: boolean
    hideButton?: boolean
    isLithium?: boolean
    isDeleted?: boolean
    handleOptional: () => void
    versionData?: any
}

const NovoConfgInfoCard = (props: NovoConfgInfoCardProps) => {
    const {
        translation: { t },
    } = useTranslationData()
    const {
        machine: {
            'Quote Number:': quoteNumber,
            'Series Code': seriesCode,
            'Model Code': modelCode,
            'List Price': listPrice,
            Discount,
            'Net Price Each': netPriceEach,
            // 'Customer Price': customerPrice,
            Quantity,
        },
        isLithium,
        isDeleted,
        handleOptional,
        showOptional,
        hideButton,
        versionData,
    } = props
    const { orderId, novoId, configurationId } = useParams()
    const naviagte = useNavigate()
    const [siteSurveyModal, setSiteSurveyModal] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [retailPrice, setRetailPrice] = useState<number>(0)
    const [discountRate, setDiscountRate] = useState<number>(0)
    const [totalPrice, setTotalQuotePrice] = useState<number>(0)
    const imageUrl = process.env.REACT_APP_S3_IMAGE_URL

    const { isLoading: initialPricingLoading } = useQuery(
        ['getPricingInitialData'],
        () =>
            PricingQueries.getInitialValueForPricing(
                parseInt(String(novoId || configurationId), 10),
            ),
        {
            enabled: !!(novoId || configurationId) && !versionData,
            refetchIntervalInBackground: false,
            refetchInterval: false,
            onSuccess: (d: any) => {
                const initialData = d.data.data
                setRetailPrice(initialData.list_price)
                const totalNetValueParts = initialData.total_net_value_parts
                const totalOptions = initialData.total_options_selected
                const sprDiscount = initialData.special_price_rate
                    ? initialData.special_price_rate
                    : initialData.standard_discount
                const standardDiscount = initialData.standard_discount
                const tempDiscountRate =
                    sprDiscount > standardDiscount ? sprDiscount : standardDiscount
                setDiscountRate(tempDiscountRate)
                const discountAmount = (initialData.machine_list_price * tempDiscountRate) / 100
                const discountedAmount = initialData.machine_list_price - discountAmount
                const totalCostPriceIncludingDiscount =
                    discountedAmount + totalNetValueParts - totalOptions
                setTotalQuotePrice(totalCostPriceIncludingDiscount)
            },
        },
    )

    const { isLoading: imageLoading, data: imageData } = useQuery(
        ['getConfigurationImage'],
        () => getConfigurationImage(parseInt(String(novoId || configurationId), 10)),
        {
            enabled: !!(novoId || configurationId),
            onError: () => toast.error(t('message.image_fetch_failed')),
        },
    )

    const deleteNovoConfigurationMutation = useMutation(
        ['deleteNovoConfiguration'],
        () => deleteNovoConfiguration(String(novoId)),
        {
            onSuccess: () => {
                toast(`${t('message.delete_novo_configuration_success')}`, successToast)
                naviagte('/novo-configuration')
            },
            onError: (error: { message: string }) => {
                toast(
                    error?.message || `${t('message.delete_novo_configuration_failed')}`,
                    errorToast,
                )
                naviagte('/novo-configuration')
            },
        },
    )

    if (initialPricingLoading) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <>
            <div className="flex justify-between items-start gap-8 w-full">
                <div className="flex-col w-1/3 gap-10 hidden md:block">
                    <div className={classNames('rounded-lg bg-white h-[360px] drop-shadow-md')}>
                        {imageLoading ? (
                            <Loading />
                        ) : (
                            <img
                                src={
                                    imageData?.data?.data?.length > 0
                                        ? `${imageUrl}${imageData?.data?.data[0].image}`
                                        : `${defaultImage}`
                                }
                                alt=""
                                className="object-contain rounded-md"
                            />
                        )}
                    </div>
                </div>
                <div className="flex-1 min-w-[24rem] flex flex-col gap-9">
                    <div className="flex justify-between items-center">
                        <div className="flex flex-col gap-2">
                            <p className="text-2xl flex items-center gap-2">
                                {isLithium && (
                                    <span
                                        title={t('message.configuration_based_lithium_battery')}
                                        className="text-green-700 cursor-pointer"
                                    >
                                        <FaLeaf size={20} />
                                    </span>
                                )}
                                {isDeleted && (
                                    <span
                                        title={t('api.configuration_id_deleted_sequence')}
                                        className="text-red-700 cursor-pointer"
                                    >
                                        <GrFlagFill size={20} />
                                    </span>
                                )}
                                {modelCode} - {seriesCode}{' '}
                            </p>
                        </div>
                        <div className="text-md flex flex-col gap-3">
                            <span>{t('api.quote_number')}: </span>
                            <span>{quoteNumber}</span>
                        </div>
                    </div>
                    <div
                        className={classNames(
                            ' p-8 drop-shadow-md bg-white rounded-lg',
                            hideButton ? 'h-[38.5vh]' : 'h-fit',
                        )}
                    >
                        <table className="table-auto w-[100%]">
                            <tbody>
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {t('api.retail_price')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                        {versionData
                                            ? versionData.total_price
                                            : formatCurrency(retailPrice)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {t('api.discount')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                        {versionData ? versionData.total_discount : discountRate} %
                                    </td>
                                </tr>
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {t('api.quantity')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                        {Quantity}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={1000}>
                                        <hr />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {t('api.net_total_each')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                        {versionData
                                            ? versionData.total_net_price
                                            : formatCurrency(totalPrice)}
                                    </td>
                                </tr>
                                {/* <tr>
                                    <th className="px-4 ptotal_net_pricey-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {t('api.total_customer_price')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                        {formatCurrency(
                                            customerPrice,
                                            country.currency || country.country_code || '',
                                        )}
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                    {!hideButton && (
                        <div className="flex justify-end flex-wrap w-full gap-4">
                            {/* <a href="#">
                                <Button label={t('api.connected_quote')} variant="outline" />
                            </a> */}
                            {!orderId && (
                                <div>
                                    <Button
                                        label={
                                            showOptional
                                                ? `${t('api.hide')} ${t('api.optional_parts')}`
                                                : `${t('api.show')} ${t('api.optional_parts')}`
                                        }
                                        variant={!showOptional ? 'outline' : 'primary'}
                                        onClick={() => handleOptional()}
                                    />
                                </div>
                            )}
                            {!orderId && (
                                <div>
                                    <Button
                                        label={t('api.site_survey')}
                                        variant="outline"
                                        onClick={() => setSiteSurveyModal(true)}
                                    />
                                </div>
                            )}
                            {/* <div>
                                <Button label={t('api.pricing')} variant="outline" />
                            </div> */}

                            {/* <div>
                                <Button
                                    label="Delete Configuration"
                                    variant="outline"
                                    onClick={() => setDeleteModal(true)}
                                />
                            </div> */}
                        </div>
                    )}
                </div>
            </div>
            <Modal
                center
                open={siteSurveyModal}
                onClose={() => setSiteSurveyModal(false)}
                showCloseIcon={false}
                classNames={{
                    root: 'rounded-lg',
                    modal: 'rounded-lg w-[80%]',
                }}
            >
                <ConfigurationSiteSurvey isNovo closeModal={() => setSiteSurveyModal(false)} />
            </Modal>
            <DeleteModal
                modal={deleteModal}
                loading={deleteNovoConfigurationMutation.isLoading}
                closeModal={() => setDeleteModal(false)}
                label={t('message.delete_configuration')}
                title={`${t('api.delete_configuration')} ${modelCode} - ${seriesCode}`}
                onConfirm={() => deleteNovoConfigurationMutation.mutate()}
            />
        </>
    )
}

export default NovoConfgInfoCard
