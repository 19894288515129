/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import Button from 'components/button'
import settingImage from 'assets/svg/setting.png'
import DeleteModal from 'components/modal/deleteModal'
import { useState } from 'react'
import classNames from 'classnames'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DuplicateModal from '../duplicateModal'
import { useAuth } from '../../../auth/context/useAuth'
import { ConfigurationQueries } from '../../queries/configuration'
import { errorToast, successToast } from '../../../../utils/toasterUtil'
import DiscountRequestModal from '../discountRequestModal'

const imageUrl = process.env.REACT_APP_IMAGE_URL

interface ConfigurDeetailProp {
    machine: {
        configuration_id: string
        machine_category_image: string
        machine_total_price: number
        quantity: number
        name: string
        machine_category: string
        machine_name: string
        log_configuration_id: string
        discount_percentage: number
        discount_amount: number
        extra_discount: number
        dealer_discount_amount: number
        currency: string
        country_code: string
        oem_discount: number
        sales_margin: number
        end_user_sales_price: number
        sales_margin_amount: number
        quote_id: number
        trade_in_value?: number
    }
    orderId?: boolean
    hideImage: boolean
}

const ConfigureDetail = (props: ConfigurDeetailProp) => {
    const { machine, orderId, hideImage } = props
    const { from } = useParams()
    const { t } = useTranslation()
    const { user } = useAuth()
    const { userExtra } = useAuth()
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [configuationOrder, setConfiguationOrder] = useState<boolean>(false)
    const [duplicateModal, setDuplicateModal] = useState<boolean>(false)
    const [higherDiscountModal, setHigherDiscountModal] = useState<boolean>(false)

    const navigate = useNavigate()

    useQuery(
        ['checkIfConfigurationIsorder', machine.configuration_id],
        () =>
            ConfigurationQueries.checkIfConfigurationIsOrderd({
                configurationId: machine.configuration_id,
            }),
        {
            onSuccess: (checkConfiguration: any) => {
                setConfiguationOrder(
                    Boolean(checkConfiguration?.data?.message.toLowerCase().includes('not')),
                )
            },
        },
    )

    const { data: hasChildren } = useQuery(
        ['checkUserHasChildren', user.id],
        () =>
            ConfigurationQueries.getUserHasChildren({
                userId: parseInt(user.id, 10),
            }),
        {
            enabled: !!user.id,
        },
    )

    const { data: getStatusOfLibraryConfiguration, refetch } = useQuery(
        ['getLibraryConfigurationStatus', machine.configuration_id],
        () =>
            ConfigurationQueries.getStatusOfLibraryConfiguration({
                configurationId: parseInt(machine.configuration_id, 10),
            }),
        {
            enabled: !!machine.configuration_id && !!from,
        },
    )

    const changeConfigurationStatusMutation = useMutation(
        ['changeConfigurationConfiguration'],
        (params: { configurationId: number; configurationStatus: number }) => {
            return ConfigurationQueries.changeLibraryConfigurationStatus({
                configurationId: params.configurationId,
                configurationStatus: params.configurationStatus,
            })
        },
        {
            onSuccess: libraryStatus => {
                refetch()
                toast(
                    libraryStatus?.data?.message || t('message.status_change_success'),
                    successToast,
                )
            },
            onError: (error: any) => {
                toast(error?.message || t('message.status_change_failed'), successToast)
            },
        },
    )

    const deleteMutation = useMutation(
        ['deleteConfiguration'],
        (configurationId: number) => ConfigurationQueries.deleteConfiguration({ configurationId }),
        {
            onSuccess: data => {
                navigate('/configuration')
                toast(
                    data?.data?.message || t('message.delete_configuration_success'),
                    successToast,
                )
            },
            onError: (error: any) => {
                toast(error?.message || t('message.delete_configuration_failed'), successToast)
            },
        },
    )

    const handleDelete = (configurationId: number, quoteId?: number) => {
        if (!quoteId) {
            deleteMutation.mutate(configurationId)
        } else {
            toast(t('message.configuration_already_used_cannot_delete'), errorToast)
        }
    }

    const handleEditConfiguration = () => {
        if (window.location.href.includes('configuration/library')) {
            navigate(`/configuration/edit/${machine.configuration_id}/library`)
        } else {
            navigate(`/configuration/edit/${machine.configuration_id}`)
        }
    }

    const handleDuplicateModal = () => {
        setDuplicateModal(!duplicateModal)
    }

    const handleDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const handleDiscountModal = () => {
        setHigherDiscountModal(true)
    }

    const dealerNetTotal =
        machine.machine_total_price / machine.quantity -
        (machine.discount_percentage * machine.machine_total_price) / 100 -
        machine.discount_amount -
        machine.dealer_discount_amount
    const netTotal =
        machine.machine_total_price / machine.quantity -
        (machine.discount_percentage * machine.machine_total_price) / machine.quantity / 100 -
        machine.discount_amount -
        machine.dealer_discount_amount
    const netNetPurchasePrice =
        machine.machine_total_price / machine.quantity -
        (machine.discount_percentage * (machine.machine_total_price / machine.quantity)) / 100 -
        machine.discount_amount -
        machine.dealer_discount_amount -
        machine.oem_discount
    const grandTotal = netTotal * machine.quantity

    return (
        <div
            className={classNames(
                'flex justify-between items-center',
                orderId || machine.quote_id ? 'gap-6' : 'gap-20',
            )}
        >
            {(!orderId || !hideImage) && (
                <div className="flex-col w-1/3 gap-10 hidden md:block">
                    <div className={classNames('rounded-lg bg-white drop-shadow-md p-8')}>
                        <img
                            src={`${
                                machine.machine_category_image
                                    ? `${imageUrl}${machine.machine_category_image}`
                                    : settingImage
                            }`}
                            alt=""
                            className="object-contain"
                        />
                    </div>
                </div>
            )}

            <div className="flex-1 min-w-[24rem] flex flex-col gap-9">
                <div className="flex flex-col gap-2">
                    <p className="text-2xl">{machine.name || ''}</p>
                    {machine?.log_configuration_id && (
                        <p className="text-lg text-placeholderColor font-bold">
                            {machine?.machine_name || ''}-{machine?.log_configuration_id || ''}
                        </p>
                    )}
                    <p className="text-lg text-placeholderColor font-bold">
                        {machine.machine_category || ''}
                    </p>
                </div>
                <div className=" p-8 drop-shadow-md bg-white rounded-lg">
                    <table className="table-auto w-[100%]">
                        <tbody>
                            <tr>
                                <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    {t('api.retail')}
                                </th>
                                <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                    {formatCurrency(
                                        (machine.machine_total_price / machine.quantity).toFixed(2),
                                    )}
                                </td>
                            </tr>
                            {machine.discount_percentage !== 0 && machine.discount_percentage && (
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {userExtra.is_dealer
                                            ? t('fields.standard_dealer_terms')
                                            : t('fields.discount_percentage')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                        {parseFloat(String(machine.discount_percentage)).toFixed(0)}
                                        {' %'}
                                    </td>
                                </tr>
                            )}
                            {machine.discount_amount !== 0 && (
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {userExtra.is_dealer
                                            ? t('api.dealer_discount_amount')
                                            : t('api.discount_amount')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                        {formatCurrency(machine.discount_amount)}
                                    </td>
                                </tr>
                            )}
                            {machine.dealer_discount_amount !== 0 &&
                                userExtra.extra_discount_visible !== 0 && (
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                            {t('fields.extra_discount')}
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                            {formatCurrency(machine.dealer_discount_amount)}
                                        </td>
                                    </tr>
                                )}
                            <tr>
                                <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    {userExtra.is_dealer
                                        ? t('fields.dealer_net_total')
                                        : t('fields.net_total')}
                                </th>
                                <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                    {userExtra.is_dealer
                                        ? formatCurrency(dealerNetTotal.toFixed(2))
                                        : formatCurrency(netTotal.toFixed(2))}
                                </td>
                            </tr>
                            {userExtra.is_dealer !== 0 && (
                                <>
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                            {t('fields.oem_discount')}
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                            {formatCurrency(machine.oem_discount.toFixed(2))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                            {t('fields.net_net_purchase_price')}
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                            {formatCurrency(netNetPurchasePrice.toFixed(2))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                            {t('fields.extra_discount')}
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                            {formatCurrency(machine.extra_discount)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                            {t('fields.extra_support')}
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                            {formatCurrency(
                                                (
                                                    (machine.extra_discount * netNetPurchasePrice) /
                                                    100
                                                ).toFixed(2),
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                            {t('fields.net_net_net_price')}
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                            {formatCurrency(
                                                (
                                                    machine.machine_total_price / machine.quantity -
                                                    (machine.discount_percentage *
                                                        (machine.machine_total_price /
                                                            machine.quantity)) /
                                                        100 -
                                                    machine.discount_amount -
                                                    machine.dealer_discount_amount -
                                                    machine.oem_discount -
                                                    (machine.extra_discount *
                                                        (machine.machine_total_price /
                                                            machine.quantity -
                                                            (machine.discount_percentage *
                                                                (machine.machine_total_price /
                                                                    machine.quantity)) /
                                                                100 -
                                                            machine.discount_amount -
                                                            machine.dealer_discount_amount -
                                                            machine.oem_discount)) /
                                                        100
                                                ).toFixed(2),
                                            )}
                                        </td>
                                    </tr>
                                </>
                            )}
                            {machine && machine.sales_margin !== 0 && machine.sales_margin ? (
                                userExtra.sales_margin_visible ? (
                                    userExtra.is_dealer ? (
                                        <>
                                            <tr>
                                                <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                                    {t('fields.end_customer_purchase_price')}
                                                </th>
                                                <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                                    {formatCurrency(
                                                        machine.end_user_sales_price.toFixed(2),
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                                    {t('fields.dealer_margin_amount')}
                                                </th>
                                                <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                                    {formatCurrency(
                                                        machine.sales_margin_amount.toFixed(2),
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                                    {t('fields.dealer_margin')}
                                                </th>
                                                <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                                    {machine.sales_margin.toFixed(2)}
                                                    {' %'}
                                                </td>
                                            </tr>
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                                    {t('fields.sales_margin')}
                                                </th>
                                                <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                                    {machine.sales_margin.toFixed(2)}
                                                    {' %'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                                    {t('fields.total_invoice_for_customer')}
                                                </th>
                                                <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                                    {formatCurrency(
                                                        machine.end_user_sales_price.toFixed(2),
                                                    )}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                ) : null
                            ) : null}
                            {userExtra.customer_discount_visible && !userExtra.is_dealer ? (
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {t('fields.customer_discount')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                        {(
                                            (Math.abs(
                                                machine.machine_total_price -
                                                    (machine.machine_total_price -
                                                        (machine.machine_total_price *
                                                            machine.discount_percentage) /
                                                            100 -
                                                        machine.discount_amount -
                                                        machine.dealer_discount_amount -
                                                        machine.oem_discount -
                                                        (machine.extra_discount *
                                                            (machine.machine_total_price /
                                                                machine.quantity -
                                                                (machine.discount_percentage *
                                                                    (machine.machine_total_price /
                                                                        machine.quantity)) /
                                                                    100 -
                                                                machine.discount_amount -
                                                                machine.dealer_discount_amount -
                                                                machine.oem_discount)) /
                                                            100 +
                                                        (machine.sales_margin *
                                                            (machine.machine_total_price -
                                                                (machine.machine_total_price *
                                                                    machine.discount_percentage) /
                                                                    100 -
                                                                machine.discount_amount -
                                                                machine.dealer_discount_amount -
                                                                machine.oem_discount -
                                                                (machine.extra_discount *
                                                                    (machine.machine_total_price /
                                                                        machine.quantity -
                                                                        (machine.discount_percentage *
                                                                            (machine.machine_total_price /
                                                                                machine.quantity)) /
                                                                            100 -
                                                                        machine.discount_amount -
                                                                        machine.dealer_discount_amount -
                                                                        machine.oem_discount)) /
                                                                    100)) /
                                                            100),
                                            ) /
                                                machine.machine_total_price) *
                                            100
                                        ).toFixed(2)}
                                        {' %'}
                                    </td>
                                </tr>
                            ) : null}
                            <tr>
                                <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    {t('fields.configuration_quantity')}
                                </th>
                                <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                    {machine.quantity}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {machine && (
                        <table className="table-auto w-[100%]">
                            <tbody>
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {t('fields.final_net_total')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                        {formatCurrency(grandTotal.toFixed(2))}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {t('fields.prat_exchange_trade_in')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right   flex justify-end">
                                        {formatCurrency(String(machine?.trade_in_value || 0))}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={1000}>
                                        <hr />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="px-4 py-2 text-left text-gray-900 whitespace-nowrap">
                                        {t('fields.price_for_customer')}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap text-right">
                                        {formatCurrency(
                                            (grandTotal - (machine?.trade_in_value || 0)).toFixed(
                                                2,
                                            ),
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="flex justify-end flex-wrap w-full gap-4">
                    {window.location.href.includes('configuration/library') &&
                        hasChildren &&
                        hasChildren.data &&
                        hasChildren.data.has_children &&
                        (getStatusOfLibraryConfiguration &&
                        getStatusOfLibraryConfiguration.data &&
                        getStatusOfLibraryConfiguration.data.is_private ? (
                            <div className="w-54">
                                <Button
                                    label="Share with team"
                                    variant="primary"
                                    isLoading={changeConfigurationStatusMutation.isLoading}
                                    onClick={() =>
                                        changeConfigurationStatusMutation.mutate({
                                            configurationId: parseInt(machine.configuration_id, 10),
                                            configurationStatus: 0,
                                        })
                                    }
                                />
                            </div>
                        ) : (
                            <div className="w-54">
                                <Button
                                    label="Stop sharing"
                                    variant="error"
                                    isLoading={changeConfigurationStatusMutation.isLoading}
                                    onClick={() =>
                                        changeConfigurationStatusMutation.mutate({
                                            configurationId: parseInt(machine.configuration_id, 10),
                                            configurationStatus: 1,
                                        })
                                    }
                                />
                            </div>
                        ))}
                    {!orderId && (
                        <>
                            {configuationOrder ? (
                                <div className="w-54">
                                    <Button
                                        label="Edit Configuration"
                                        variant="primary"
                                        onClick={handleEditConfiguration}
                                    />
                                </div>
                            ) : null}
                            <div className="w-54">
                                <Button
                                    label="Duplicate and Edit"
                                    onClick={() => handleDuplicateModal()}
                                    variant="primary"
                                />
                            </div>
                            <div className="w-54">
                                <Button
                                    label="Delete Configuration"
                                    onClick={() => handleDeleteModal()}
                                    variant="error"
                                />
                            </div>
                            {userExtra.higher_discount_request ? (
                                <div className="w-48">
                                    <Button
                                        label="Higher Discount Request"
                                        onClick={() => handleDiscountModal()}
                                        variant="primary"
                                    />
                                </div>
                            ) : null}
                            {userExtra.lease_process_visible ? (
                                <a
                                    className="w-54"
                                    href={`/lease-calculation/${machine.configuration_id}`}
                                >
                                    <Button label="Lease Calculator" variant="primary" />
                                </a>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
            <DeleteModal
                modal={deleteModal}
                closeModal={() => handleDeleteModal()}
                onConfirm={() =>
                    handleDelete(parseInt(machine.configuration_id, 10), machine.quote_id)
                }
            />
            <DuplicateModal
                modal={duplicateModal}
                closeModal={() => handleDuplicateModal()}
                onConfirm={() => {
                    if (window.location.href.includes('configuration/library')) {
                        navigate(`/configuration/create/${machine.configuration_id}/library`)
                    } else {
                        navigate(`/configuration/create/${machine.configuration_id}`)
                    }
                }}
            />
            <DiscountRequestModal
                modal={higherDiscountModal}
                configurationId={machine.configuration_id}
                closeModal={() => setHigherDiscountModal(false)}
            />
        </div>
    )
}

export default ConfigureDetail
