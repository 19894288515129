/* eslint-disable camelcase */
import classNames from 'classnames'
import Button from 'components/button'
import Input from 'components/input'
import Loading from 'components/loading'
import AlertModal from 'components/modal/alertModal'
import TextArea from 'components/textArea'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { errorToast, successToast } from 'utils/toasterUtil'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'antd'
import { getCurrencySymbol } from 'utils/currency/formatCurrency'
import { REQUEST_TS_STATUS } from 'utils/reqtestTsStatus'
import {
    getDetailRequestTs,
    rejectTS,
    requestToServicer,
    updateRequestByServicer,
} from '../query/request_ts'
// import allowedRole from '../constant/allowed_role'

const wildCardRole = ''
const isModal = false

const RequestDetail = () => {
    const { requestId } = useParams()
    const navigate = useNavigate()
    const {
        // country,
        userExtra: { role_id },
    } = useAuth()
    const { t } = useTranslation()
    const [price, setPrice] = useState<string>('')
    const [isExtraMonthlyCostNeeded, setIsExtraMonthlyCostNeeded] = useState<boolean>(false)
    const [extraMonthlyCost, setExtraMonthlyCost] = useState<number>(0)
    const [errorMsg, setErrorMessage] = useState<string>('')
    const [remark, setRemark] = useState<string>('')
    const [rejectServiceModal, setRejectServiceModal] = useState<boolean>(false)
    const [rejectNotes, setRejectNotes] = useState<string>('')
    const [requestNotes, setRequestNotes] = useState<string>('')
    const [status, setStatus] = useState<REQUEST_TS_STATUS>()

    const { isLoading, isFetching, data } = useQuery(
        ['getRequestTs', requestId],
        () => getDetailRequestTs(requestId),
        {
            enabled: !!requestId,
            onSuccess: item => {
                setIsExtraMonthlyCostNeeded(Boolean(item?.data?.data?.is_service_needed))
                setPrice(item?.data?.data?.rate)
                setExtraMonthlyCost(item?.data.data?.service_rate_per_hour)
                setStatus(item?.data.data.status)
                setRemark(item?.data?.data?.latest_status[0]?.remark)
                setRequestNotes(item?.data?.data?.latest_status[0]?.remark)
            },
        },
    )

    const requestToServicerMutation = useMutation(
        (payload: any) => requestToServicer(requestId, payload),
        {
            onSuccess: () => {
                toast(t('message.send_to_workshop_success'), successToast)
                setRequestNotes('')
                navigate(`/request-ts`, {
                    state: {
                        isRefetch: true,
                    },
                })
            },
            onError: (error: any) => {
                toast(t('message.send_to_workshop_failed'), errorToast)
            },
        },
    )

    const rejectTSMutation = useMutation((payload: any) => rejectTS(requestId, payload), {
        onSuccess: () => {
            toast(t('message.request_reject_success'), successToast)
            setRejectServiceModal(false)
            setRejectNotes('')
            navigate(`/request-ts`, {
                state: {
                    isRefetch: true,
                },
            })
        },
        onError: (error: any) => {
            toast(t('message.request_reject_failed'), errorToast)
        },
    })

    const updateRequestByServicerMutation = useMutation(
        (payload: any) => updateRequestByServicer(requestId, payload),
        {
            onSuccess: () => {
                toast(t('message.request_updated_success'), successToast)
                navigate(`/request-ts`, {
                    state: {
                        isRefetch: true,
                    },
                })
            },
        },
    )

    const handleRequestToServicer = () => {
        requestToServicerMutation.mutate({
            remark: requestNotes,
            is_service_needed: isExtraMonthlyCostNeeded,
        })
    }

    const handleRejectService = () => {
        rejectTSMutation.mutate({
            remark: rejectNotes,
        })
    }

    if (isLoading || isFetching) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-8 bg-white h-full w-full p-4 rounded-md">
            <div className="flex gap-8 items-start justify-between w-full">
                <div className={classNames('flex flex-col gap-4', isModal ? 'flex-1' : 'w-1/2')}>
                    <div className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                        {t('api.request_item_detail')}
                    </div>
                    <div className="flex flex-col gap-2">
                        <p>
                            {t('api.configuration_quote_number')}: {data?.data?.data.quote_number}
                        </p>
                        <p>
                            {t('api.price_request')}: {data?.data?.data.rate}
                        </p>
                        <p>
                            {t('api.quantity')}: {data?.data?.data.quantity}
                        </p>
                        <div className="flex flex-col gap-1">
                            <p>{t('api.description')}:</p>
                            <p className={classNames('text-sm', isModal ? 'w-full' : 'w-2/3')}>
                                {data?.data?.data?.description}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4 flex-1">
                    <p className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                        {t('api.configuration_detail')}
                    </p>
                    <div className="flex flex-col gap-2">
                        <div>
                            {t('api.model')}: {data?.data?.data?.novo_configuration?.model}
                        </div>
                        <div>
                            {t('api.configuration_quote_number')}:{' '}
                            {data?.data?.data?.novo_configuration?.novo_quote_number}
                        </div>
                        <p>
                            {t('api.quote_name')}: {data?.data?.data?.quote_name}
                        </p>
                        <div>
                            {t('api.quantity')}: {data?.data?.data?.novo_configuration?.quantity}
                        </div>
                        <div className="flex flex-col gap-1">
                            <p>{t('api.description')}:</p>
                            <p className={classNames('text-sm', isModal ? 'w-full' : 'w-2/3')}>
                                {data?.data?.data?.novo_configuration?.description}
                            </p>
                        </div>
                    </div>
                    <Link
                        to={`/request-ts/${requestId}/${data?.data?.data?.quote_id}/${data?.data?.data?.novo_configuration?.id}?is_novo=true`}
                        className="text-primary"
                        replace
                    >
                        {t('api.view_configuration')}
                    </Link>
                    <Link
                        to={`/request-ts/${requestId}/${data?.data?.data?.quote_id}`}
                        className="text-primary"
                        replace
                    >
                        {t('api.view_quote')}
                    </Link>
                </div>
            </div>
            {/* case of reject */}
            {parseInt(data?.data?.data?.status, 10) === 3 && (
                <div className="flex items-center justify-center">
                    <div className="flex flex-col items-starts bg-blue-200 bg-opacity-75 rounded-md p-4 gap-2 w-full md:w-3/4 ">
                        <p className="text-md">
                            {t('api.this_request_has_been_rejected')}{' '}
                            {t(
                                'api.in_case_of_questions_please_contact_technical_support_or_sales_support',
                            )}
                        </p>
                        <div className="flex flex-col">
                            <p className="text-gray-300">{t('api.reason_of_rejection')}</p>
                            <p className="text-base-400">
                                {data?.data?.data?.latest_status[0]?.remark}
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {(role_id === ROLE_TYPE.technical_support || wildCardRole) &&
                parseInt(data?.data?.data?.status, 10) !== 3 && (
                    <>
                        <div className="flex flex-col gap-6">
                            <div>
                                <p className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                                    {t('api.request_for_price')}
                                </p>
                            </div>
                            <div className="flex flex-1 items-center gap-4">
                                <p className="text-sm">{t('fields.extra_monthly_cost')}</p>
                                <Checkbox
                                    checked={isExtraMonthlyCostNeeded}
                                    onClick={() =>
                                        setIsExtraMonthlyCostNeeded(!isExtraMonthlyCostNeeded)
                                    }
                                />
                            </div>
                            <div className="flex flex-col">
                                <p className="text-left text-sm">{t('api.reason_of_request')}</p>
                                <TextArea
                                    placeholder={t('api.enter_description')}
                                    value={requestNotes}
                                    name="description"
                                    onChange={e => setRequestNotes(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-center items-center gap-4">
                            <div className="w-1/4">
                                <Button
                                    label={t('api.request_to_service')}
                                    variant="outline"
                                    disabled={requestToServicerMutation.isLoading}
                                    isLoading={requestToServicerMutation.isLoading}
                                    onClick={() => handleRequestToServicer()}
                                />
                            </div>
                            <div className="w-1/4">
                                <Button
                                    label={t('api.reject')}
                                    variant="error"
                                    onClick={() => setRejectServiceModal(true)}
                                />
                            </div>
                        </div>
                    </>
                )}
            {role_id !== ROLE_TYPE.technical_support &&
                parseInt(data?.data?.data?.status, 10) !== 3 && (
                    <div className="flex flex-col gap-4">
                        <p className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                            {t('api.update_price_for_this_request')}
                        </p>
                        <div className="w-full">
                            <Input
                                value={price}
                                type="number"
                                name="price"
                                horizontal
                                label={t('api.update_price')}
                                error={errorMsg}
                                // inputWidth="12rem"
                                disabled={updateRequestByServicerMutation.isLoading}
                                onChange={e => {
                                    setPrice(e.target.value)
                                    setErrorMessage('')
                                }}
                            />
                        </div>
                        {/* <div className="flex justify-between gap-4">
                            <div className="flex w-full gap-4">
                                {isExtraMonthlyCostNeeded && (
                                    <div className="flex-1">
                                        <Input
                                            name="extraMonthCost"
                                            value={extraMonthlyCost}
                                            type="number"
                                            horizontal
                                            inputWidth="14rem"
                                            label={t('fields.extra_monthly_cost')}
                                            onChange={e =>
                                                setExtraMonthlyCost(parseFloat(e.target.value))
                                            }
                                            leftContent={<p>{getCurrencySymbol()}</p>}
                                        />
                                    </div>
                                )}
                            </div>
                        </div> */}
                        <TextArea
                            placeholder={t('api.enter_remark')}
                            value={remark}
                            name="remark"
                            horizontal
                            label={t('fields.remark')}
                            onChange={e => setRemark(e.target.value)}
                        />

                        <div className="flex justify-end gap-4">
                            <div className="w-1/4">
                                {status !== REQUEST_TS_STATUS.DONE &&
                                    (role_id === ROLE_TYPE.servicer ||
                                        role_id === ROLE_TYPE.used_truck_support) && (
                                        <Button
                                            label={t('api.apply')}
                                            variant="outline"
                                            disabled={updateRequestByServicerMutation.isLoading}
                                            onClick={() => {
                                                if (price && parseInt(price, 10) !== 0) {
                                                    updateRequestByServicerMutation.mutate({
                                                        price,
                                                        is_service_needed: isExtraMonthlyCostNeeded,
                                                        service_rate_per_hour: extraMonthlyCost,
                                                        remark,
                                                    })
                                                } else {
                                                    toast.error(t('message.price_cannot_be_0'))
                                                    setErrorMessage(t('message.price_cannot_be_0'))
                                                }
                                            }}
                                        />
                                    )}
                            </div>
                            <div className="w-1/4">
                                {status !== REQUEST_TS_STATUS.DONE &&
                                    (role_id === ROLE_TYPE.servicer ||
                                        role_id === ROLE_TYPE.used_truck_support) && (
                                        <Button
                                            label={t('api.reject')}
                                            variant="error"
                                            onClick={() => setRejectServiceModal(true)}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                )}
            <AlertModal
                show={rejectServiceModal}
                isSubmitting={rejectTSMutation.isLoading}
                content={
                    <div className="flex flex-col gap-6 w-[400px]">
                        <div>
                            <p>{t('api.request_for_price')}</p>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-left text-sm">{t('api.reason_of_reject')}</p>
                            <TextArea
                                placeholder={t('api.enter_description')}
                                value={rejectNotes}
                                name="description"
                                onChange={e => setRejectNotes(e.target.value)}
                            />
                        </div>
                    </div>
                }
                onConfirm={() => handleRejectService()}
                onCancel={() => setRejectServiceModal(false)}
            />
        </div>
    )
}

export default RequestDetail
