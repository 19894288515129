import classNames from 'classnames'
import Button from 'components/button'
import { useFormik } from 'formik'
import { orderBy } from 'lodash'
import { useMutation } from 'react-query'
import * as Yup from 'yup'

import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { USED_TRUCK_ROUTE } from 'route/appRoutes'
import FileUpload from '../../../components/FileUpload'
import useTranslationData from '../../translation/hooks/useTranslationData'
import ExcelTable from '../components/ExcelTable'
import { importStock, importUsedTruckInfo, parseExcelData } from '../query'

const cardClass = 'bg-white px-4 py-4 rounded-xl h-fit'

const NovoImport = () => {
    const {
        translation: { t },
    } = useTranslationData()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    const isStock = window.location.pathname?.includes('import-stock')
    const isUsedTruck = window.location.pathname?.includes(USED_TRUCK_ROUTE)
    const isUsedTruckInfo = searchParams.get('used-truck-info')

    const parseExcelDataFunc = useMutation(
        ['import-novo-configuration'],
        (params: { excel_file: File; isUsedTruck: boolean }) =>
            isUsedTruckInfo
                ? importUsedTruckInfo(params.excel_file)
                : isStock
                ? importStock(params.excel_file, params.isUsedTruck)
                : parseExcelData(params.excel_file, params.isUsedTruck),
        {
            onSuccess: () => {
                if (isUsedTruckInfo) {
                    navigate(USED_TRUCK_ROUTE, {
                        state: {
                            activeTab: {
                                name: 'api.used_truck_details',
                                value: 'used_truck_details',
                            },
                        },
                    })
                }
            },
            onError: (error: Error) => {
                toast.error(error.message || t('message.import_error'))
            },
        },
    )

    const novoImportFormik = useFormik<any>({
        initialValues: {
            files: [],
        },
        validationSchema: Yup.object().shape({
            files: Yup.array().length(1, 'Please upload only one file'),
        }),
        onSubmit: (values, { resetForm }) => {
            parseExcelDataFunc.mutate({
                excel_file: values.files[0],
                isUsedTruck,
            })
            resetForm()
        },
    })

    return (
        <div className="flex flex-col gap-8">
            <div className={classNames('flex flex-col gap-8', cardClass)}>
                <div className="text-secondary border-primary font-medium underline underline-offset-8 decoration-4 decoration-primary">
                    {isStock
                        ? t('api.upload_stock')
                        : isUsedTruck
                        ? isUsedTruckInfo
                            ? t('api.upload_used_truck_info')
                            : t('api.upload_used_truck')
                        : t('api.upload_configurations')}
                </div>
                <div>
                    <div className="flex flex-col items-end gap-4">
                        <FileUpload
                            files={novoImportFormik.values.files}
                            setFiles={files => novoImportFormik.setFieldValue('files', files)}
                            max={1}
                            error={
                                novoImportFormik.errors.files && novoImportFormik.touched.files
                                    ? String(novoImportFormik.errors.files)
                                    : ''
                            }
                        />
                        <div className="w-fit flex items-end gap-4">
                            <Button
                                id="file_submit"
                                label={
                                    isUsedTruckInfo
                                        ? t('api.extract_used_truck_info')
                                        : isStock
                                        ? t('api.extract_stock_data')
                                        : t('api.extract_data')
                                }
                                variant="outline"
                                type="button"
                                isLoading={parseExcelDataFunc.isLoading}
                                onClick={() => novoImportFormik.handleSubmit()}
                            />
                            <Link
                                to={isUsedTruck ? USED_TRUCK_ROUTE : '/novo-configuration'}
                                state={{
                                    activeTab: isStock
                                        ? {
                                              name: 'api.stock',
                                              value: 'stock',
                                          }
                                        : {
                                              name: 'api.novo_configuration',
                                              value: 'novo_configuration',
                                          },
                                }}
                            >
                                <Button
                                    id="back"
                                    label={t('api.back')}
                                    type="button"
                                    disabled={parseExcelDataFunc.isLoading}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {parseExcelDataFunc?.data?.data ? (
                <div className={classNames(cardClass)}>
                    <ExcelTable
                        isUsedTruck={isUsedTruck}
                        showStockQty={isStock}
                        excelData={
                            orderBy(
                                parseExcelDataFunc?.data?.data.data,
                                ['updated_at'],
                                ['desc'],
                            ) || []
                        }
                        isLoading={parseExcelDataFunc.isLoading}
                    />
                </div>
            ) : (
                <p className="text-center">{t('api.import_text')}</p>
            )}
        </div>
    )
}

export default NovoImport
