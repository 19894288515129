/* eslint-disable jsx-a11y/anchor-is-valid */

import * as AntD from 'antd'
import { useQueries } from 'react-query'

import { toast } from 'react-toastify'

import Button from 'components/button'
import { map } from 'lodash'
import { useAuth } from 'modules/auth/context/useAuth'
import {
    getAllNovoConfigurationRemaining,
    getAllRemainingStock,
    getAllRemainingUsedTruck,
} from 'modules/quotes/queries/quotes'
import { useTranslation } from 'react-i18next'
import { FaBolt, FaLeaf } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../../components/loading'

const size = 'large'
interface ConfigurationSelectionProps {
    changeStep: (step: number) => void
    formik: any
    configurationList: any
    setConfigurationList: (configuration: any) => void
    stockList: any
    setStockList: (stock: any) => void
    usedTruckList: any
    setUsedTruckList: (usedTruck: any) => void
}

const ConfigurationSelection = (props: ConfigurationSelectionProps) => {
    const {
        changeStep,
        formik,
        configurationList,
        setConfigurationList,
        stockList,
        setStockList,
        usedTruckList,
        setUsedTruckList,
    } = props

    const navigate = useNavigate()
    const { t } = useTranslation()

    const quoteInfos = useQueries([
        {
            queryKey: ['configurationList', formik.values.user],
            queryFn: () => getAllNovoConfigurationRemaining(),
            onSuccess: (data: any) => {
                if (data?.data.data.length === 0) {
                    toast(t('message.no_configuration_found_for_the_user'), { type: 'error' })
                }
                setConfigurationList(data?.data.data || [])
            },
            retryOnMount: true,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        },
    ])

    const stockInfos = useQueries([
        {
            queryKey: ['stockList'],
            queryFn: () => getAllRemainingStock(),
            onSuccess: (data: any) => {
                if (data?.data.data.length === 0) {
                    toast(t('message.no_stock_found_for_the_user'), { type: 'error' })
                }
                setStockList(data?.data.data || [])
            },
            retryOnMount: true,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        },
    ])

    const usedTruckInfo = useQueries([
        {
            queryKey: ['usedTruckList'],
            queryFn: () => getAllRemainingUsedTruck(),
            onSuccess: (data: any) => {
                if (data?.data.data.length === 0) {
                    toast(t('message.no_used_truck_found_for_the_user'), { type: 'error' })
                }
                setUsedTruckList(data?.data.data || [])
            },
            retryOnMount: true,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        },
    ])

    const isLoading =
        quoteInfos.some(result => result.isLoading) ||
        stockInfos.some(result => result.isLoading) ||
        usedTruckInfo.some(result => result.isLoading)

    if (isLoading) {
        return (
            <div className="flex justify-center items-center bg-white px-8 py-4 rounded-xl gap-4">
                <Loading />
            </div>
        )
    }

    return (
        <div className="h-full bg-white py-8 px-8 rounded-lg flex justify-between flex-col gap-4">
            <div>
                <h1 className="text-2xl text-center">{t('api.selection')}</h1>
                {configurationList.length > 0 ||
                stockList.length > 0 ||
                usedTruckList.length > 0 ? (
                    <div className="flex gap-3 my-4 w-full justify-center items-center">
                        <p className="text-center">{t('message.select_configuration_message')}?</p>
                    </div>
                ) : (
                    <div className="flex w-full justify-center items-center">
                        <p className="text-center">
                            {t('message.no_configuration_available_want_to_create')}?
                        </p>
                        <div className="w-fit">
                            <Button
                                type="button"
                                label={t('api.create_new_configuration')}
                                variant="ghost"
                                onClick={() => navigate('/novo-configuration')}
                            />
                        </div>
                    </div>
                )}
                <div className="flex flex-col gap-4 justify-center align-center items-center">
                    <AntD.Form.Item
                        name="configuration"
                        className="flex flex-col w-[48rem]"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <div>
                            <h5 className="capitalize">{t('api.select_novo_configuration')}</h5>
                        </div>
                        <AntD.Select
                            defaultValue={formik.values.configurations || []}
                            onChange={item => {
                                formik.setFieldValue('configurations', item)
                            }}
                            mode="multiple"
                            filterOption={(input: any, option: any) =>
                                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            className="rounded-lg"
                            size={size}
                        >
                            {map(configurationList || [], (option: any) => (
                                <AntD.Select.Option value={option.value} key={option.value}>
                                    <div className="flex items-center gap-2">
                                        {option.lithium_battery && (
                                            <span
                                                title={t(
                                                    'api.configuration_based_on_lithium_battery',
                                                )}
                                                className="text-green-700 cursor-pointer"
                                            >
                                                <FaLeaf size={15} />
                                            </span>
                                        )}
                                        {option.is_electric && (
                                            <div
                                                title={t('api.configuration_based_on_electric')}
                                                className="text-yellow-400 cursor-pointer"
                                            >
                                                <FaBolt size={15} />
                                            </div>
                                        )}
                                        <span>
                                            {option.label} ({option.part_number})
                                        </span>
                                    </div>
                                </AntD.Select.Option>
                            ))}
                        </AntD.Select>
                        {formik.errors &&
                            formik.errors.configurations &&
                            formik.touched.configurations && (
                                <p className="text-red-600 mt-1">{formik.errors.configurations}</p>
                            )}
                    </AntD.Form.Item>

                    <AntD.Form.Item
                        name="configuration"
                        className="flex flex-col w-[48rem]"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <div>
                            <h5 className="capitalize">{t('api.select_stock_configuration')}</h5>
                        </div>
                        <AntD.Select
                            defaultValue={formik.values.stocks || []}
                            onChange={item => {
                                formik.setFieldValue('stocks', item)
                            }}
                            mode="multiple"
                            filterOption={(input: any, option: any) =>
                                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            className="rounded-lg"
                            size={size}
                        >
                            {map(stockList || [], (option: any) => (
                                <AntD.Select.Option value={option.value} key={option.value}>
                                    <div className="flex items-center gap-2">
                                        {option.lithium_battery && (
                                            <span
                                                title={t(
                                                    'api.configuration_based_on_lithium_battery',
                                                )}
                                                className="text-green-700 cursor-pointer"
                                            >
                                                <FaLeaf size={15} />
                                            </span>
                                        )}
                                        {option.is_electric && (
                                            <div
                                                title={t('api.configuration_based_on_electric')}
                                                className="text-yellow-400 cursor-pointer"
                                            >
                                                <FaBolt size={15} />
                                            </div>
                                        )}
                                        <span>
                                            {option.label} ({option.part_number}) (
                                            {t('api.available')} {option.remaining_quantity})
                                        </span>
                                    </div>
                                </AntD.Select.Option>
                            ))}
                        </AntD.Select>
                        {formik.errors && formik.errors.stocks && formik.touched.stocks && (
                            <p className="text-red-600 mt-1">{formik.errors.stocks}</p>
                        )}
                    </AntD.Form.Item>
                    <AntD.Form.Item
                        name="configuration"
                        className="flex flex-col w-[48rem]"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <div>
                            <h5 className="capitalize">
                                {t('api.select_used_truck_configuration')}
                            </h5>
                        </div>
                        <AntD.Select
                            defaultValue={formik.values.usedTruck || []}
                            onChange={item => {
                                formik.setFieldValue('usedTrucks', item)
                            }}
                            mode="multiple"
                            filterOption={(input: any, option: any) =>
                                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            className="rounded-lg"
                            size={size}
                        >
                            {map(usedTruckList || [], (option: any) => (
                                <AntD.Select.Option value={option.value} key={option.value}>
                                    <div className="flex items-center gap-2">
                                        {option.lithium_battery && (
                                            <span
                                                title={t(
                                                    'api.configuration_based_on_lithium_battery',
                                                )}
                                                className="text-green-700 cursor-pointer"
                                            >
                                                <FaLeaf size={15} />
                                            </span>
                                        )}
                                        {option.is_electric && (
                                            <div
                                                title={t('api.configuration_based_on_electric')}
                                                className="text-yellow-400 cursor-pointer"
                                            >
                                                <FaBolt size={15} />
                                            </div>
                                        )}
                                        <span>
                                            {option.label} ({option.part_number})
                                        </span>
                                    </div>
                                </AntD.Select.Option>
                            ))}
                        </AntD.Select>
                        {formik.errors && formik.errors.usedTrucks && formik.touched.usedTrucks && (
                            <p className="text-red-600 mt-1">{formik.errors.usedTrucks}</p>
                        )}
                    </AntD.Form.Item>
                </div>
            </div>
            <div className="flex justify-end align-center items-center gap-8">
                <div className="w-48">
                    <Button
                        type="button"
                        variant="outline"
                        label={t('api.cancel')}
                        isLoading={false}
                        onClick={() => changeStep(0)}
                    />
                </div>
                <div className="w-48">
                    <Button
                        variant="primary"
                        type="button"
                        label={t('api.next')}
                        disabled={Boolean(configurationList.length === 0)}
                        isLoading={false}
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
        </div>
    )
}

export default ConfigurationSelection
