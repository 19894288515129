import AlertModal from 'components/modal/alertModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillDelete } from 'react-icons/ai'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { deleteNovoConfigurationByObjectNumber, deleteUsedTruckInfoByObjectNumber } from '../query'

interface UsedTruckListActionProps {
    objectNumber: string
    refetch: () => void
}

const UsedTruckListAction = (props: UsedTruckListActionProps) => {
    const { t } = useTranslation()
    const { objectNumber, refetch } = props
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showDeleteUsedTruckConfiguration, setShowDeleteUsedTruckConfiguration] =
        useState<boolean>(false)

    const deleteUsedTruckInfoByObjectNumberMutation = useMutation(
        ['deleteUsedTruckInfoByObjectNumber'],
        () => deleteUsedTruckInfoByObjectNumber(String(objectNumber)),
        {
            onSuccess: () => {
                toast.success(`${t('message.delete_used_truck_configuration_success')}`)
                setShowDeleteModal(false)
                setShowDeleteUsedTruckConfiguration(true)
            },
            onError: (error: { message: string }) => {
                toast.error(
                    error?.message || `${t('message.delete_used_truck_configuration_failed')}`,
                )
                setShowDeleteModal(false)
            },
        },
    )

    const deleteUsedTruckConfigurationByObjectNumberMutation = useMutation(
        ['deleteUsedTruckConfigurationByObjectNumber'],
        () => deleteNovoConfigurationByObjectNumber(String(objectNumber)),
        {
            onSuccess: () => {
                toast.success(`${t('message.delete_used_truck_configuration_success')}`)
                if (refetch) {
                    refetch()
                }
                setShowDeleteUsedTruckConfiguration(false)
            },
            onError: (error: { message: string }) => {
                toast.error(
                    error?.message || `${t('message.delete_used_truck_configuration_failed')}`,
                )
                setShowDeleteUsedTruckConfiguration(false)
            },
        },
    )

    return (
        <div className="flex items-center justify-center gap-2">
            <button
                type="button"
                onClick={() => {
                    setShowDeleteModal(true)
                }}
            >
                <AiFillDelete size={20} />
            </button>
            <AlertModal
                show={showDeleteModal}
                isSubmitting={deleteUsedTruckInfoByObjectNumberMutation.isLoading}
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={() => deleteUsedTruckInfoByObjectNumberMutation.mutate()}
                content={<p>{t('message.delete_used_truck_configuration_id')}</p>}
            />
            <AlertModal
                show={showDeleteUsedTruckConfiguration}
                isSubmitting={deleteUsedTruckConfigurationByObjectNumberMutation.isLoading}
                onCancel={() => setShowDeleteUsedTruckConfiguration(false)}
                onConfirm={() => deleteUsedTruckConfigurationByObjectNumberMutation.mutate()}
                content={<p>{t('message.delete_used_truck_info')}</p>}
            />
        </div>
    )
}

export default UsedTruckListAction
