import { ROLE_TYPE } from 'modules/auth/context/useAuth'
import { callAxios } from 'plugins/api'

export const getRequestTs = (userRole: number) => {
    return callAxios({
        url: `request-ts-form${
            userRole === ROLE_TYPE.servicer
                ? ''
                : userRole === ROLE_TYPE.technical_support
                ? // ? 'requested-ts'
                  ''
                : userRole === ROLE_TYPE.service_manager
                ? ''
                : userRole === ROLE_TYPE.used_truck_support
                ? '/all-request-by-used-truck-support'
                : '/all-request-by-user'
        }`,
        isHeffiq: true,
        method: 'GET',
    })
}

export const getRequestTsViaUser = (userId: string | undefined) => {
    return callAxios({
        url: `request-ts-form/user/${userId}`,
        isHeffiq: true,
        method: 'GET',
    })
}

export const getDetailRequestTs = (requestId: string | undefined) => {
    return callAxios({
        url: `request-ts-form/rts-by-id/${requestId}`,
        isHeffiq: true,
        method: 'GET',
    })
}

export const requestToServicer = (requestId: string | undefined, servicerBody: any) => {
    return callAxios({
        url: `request-ts-form/to-servicer/${requestId}`,
        isHeffiq: true,
        data: servicerBody,
        method: 'POST',
    })
}

export const updateRequestByServicer = (requestId: string | undefined, servicerBody: any) => {
    return callAxios({
        url: `request-ts-form/update/${requestId}`,
        isHeffiq: true,
        data: servicerBody,
        method: 'POST',
    })
}

export const rejectTS = (requestId: string | undefined, servicerBody: any) => {
    return callAxios({
        url: `request-ts-form/reject/${requestId}`,
        isHeffiq: true,
        data: servicerBody,
        method: 'POST',
    })
}

export const updateReference = (configurationId: number, reference: string) =>
    callAxios({
        url: `novo/configurations/${configurationId}/reference`,
        method: 'PATCH',
        data: {
            reference,
        },
        isHeffiq: true,
    })

export const updateStockReference = (stockId: number, reference: string) =>
    callAxios({
        url: `novo/stock/${stockId}/reference`,
        method: 'PATCH',
        data: {
            reference,
        },
        isHeffiq: true,
    })
