/* eslint-disable */
import { useState } from 'react'
import { BiMenu, BiMessageDetail, BiUser } from 'react-icons/bi'
import { IoMdClose, IoMdNotificationsOutline } from 'react-icons/io'
import { useQuery } from 'react-query'
import { Link, NavLink } from 'react-router-dom'

import companyLogo from 'assets/heffiq/logo.svg'
import classNames from 'classnames'
import { data as navMenus } from 'constants/navLinks'
import { useAuth } from 'modules/auth/context/useAuth'
import Translation from 'modules/translation'
import { useTranslation } from 'react-i18next'
import { FaUserCircle } from 'react-icons/fa'
import { CUSTOMER_FEEDBACK_ROUTE, LOGIN_ROUTE, PROFILE_ROUTE } from 'route/appRoutes'
import appConfig from '../app.json'
import { DashboardQueries } from '../modules/dashboard/queries/dashboard'
import Button from './button'
import Dropdown from './dropdown'
import Image from './image'

const Header = () => {
    const { companyName } = appConfig
    const [mobile, setMobile] = useState<boolean>(false)
    const [navIcons, setNavIcons] = useState<
        {
            icon: JSX.Element
            link: string
        }[]
    >([])

    const { logoutUser, userExtra, user } = useAuth()
    const { t } = useTranslation()

    const profileDropDown = [
        {
            label: t('api.profile'),
            icon: <BiUser size={20} />,
            link: PROFILE_ROUTE,
        },
        //removed as per request
        // {
        //     label: 'api.discount_requests',
        //     icon: <IoMdNotificationsOutline size={20} />,
        //     link: DISCOUNT_REQUEST_ROUTE,
        // },
        {
            label: t('api.feedback'),
            icon: <BiMessageDetail size={20} />,
            link: CUSTOMER_FEEDBACK_ROUTE,
        },
    ]

    const userRole: any = userExtra?.role || 'any'

    useQuery(
        ['getDashboardCount'],
        () => DashboardQueries.getDiscountCount({ userId: user && user.id }),
        {
            onSuccess: (data: any) => {
                if (data.length) {
                    setNavIcons([
                        {
                            icon: (
                                <div>
                                    <span className="relative inline-block">
                                        <IoMdNotificationsOutline size={30} />
                                        {data?.data?.requestedNotifications > 0 && (
                                            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                                {data?.data?.requestedNotifications}
                                            </span>
                                        )}
                                    </span>
                                </div>
                            ),
                            link: '/discount-request',
                        },
                    ])
                } else {
                    setNavIcons([])
                }
            },
        },
    )

    const dropDownContent = (
        <Dropdown
            labelContent={
                <div className="flex items-center gap-2 cursor-pointer hover:text-primary">
                    <FaUserCircle size={32} />
                </div>
            }
            content={
                <>
                    <ul className="px-4 py-2 text-zinc-400	font-normal">
                        <li className="text-sm break-words">{(user && user.name) || ''}</li>
                        <li className="text-accent text-sm">{userExtra && userExtra.role}</li>
                        <li className="text-xs break-words">{(user && user.email) || ''}</li>
                        <li className="text-sm">
                            {userExtra && userExtra.country && userExtra.country.length
                                ? userExtra && userExtra.country[0]?.country_name
                                : ''}
                        </li>
                    </ul>
                    <ul className="flex flex-col gap-2" aria-labelledby="dropdownLargeButton">
                        {profileDropDown &&
                            profileDropDown.map((item, index) => (
                                <li key={item.label + index}>
                                    <Link
                                        to={item.link}
                                        className="flex items-center gap-2 justify-start p-2 w-full text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 "
                                    >
                                        <span>{item.icon}</span>
                                        <span> {item.label}</span>
                                    </Link>
                                </li>
                            ))}

                        <li className="p-2">
                            <Button
                                variant="primary"
                                onClick={() => logoutUser()}
                                label={t('api.logout')}
                            />
                        </li>
                    </ul>
                </>
            }
        />
    )

    return (
        <nav
            className={`p-2 bg-white border-gray-200 w-full ${
                mobile ? 'fixed md:relative' : 'relative'
            }`}
        >
            <div className="container flex justify-between gap-4 items-center mx-auto">
                <a
                    href="/"
                    className={`h-10 flex justify-between items-center ${
                        mobile && 'hidden md:flex'
                    }`}
                >
                    <Image
                        src={companyLogo}
                        altText={companyName}
                        height="h-8"
                        objectFit="object-cover"
                    />
                </a>
                {user && user.name ? (
                    <>
                        <div
                            className="md:hidden inline-flex justify-center items-center absolute top-4 right-4"
                            data-collapse-toggle="mobile-menu"
                            aria-controls="mobile-menu-2"
                            aria-expanded="false"
                            onClick={() => {
                                setMobile(!mobile)
                            }}
                        >
                            {mobile ? <IoMdClose size={25} /> : <BiMenu size={25} />}
                        </div>

                        {/* menus */}
                        <div
                            className={`w-full ${mobile ? 'block' : 'hidden'} md:block md:w-auto`}
                            id="mobile-menu"
                        >
                            <h2 className="mt-4 uppercase md:hidden">{t(`api.menu`)}</h2>
                            <ul className="flex flex-col md:flex-row flex-wrap gap-4 md:gap-14 md:mt-0 md:text-sm md:font-medium">
                                {navMenus &&
                                    navMenus.map(
                                        (
                                            {
                                                label,
                                                link,
                                                role,
                                                state,
                                            }: {
                                                label: string
                                                link: string
                                                role: string[]
                                                state?: any
                                            },
                                            index,
                                        ) => {
                                            if (role.length === 0) {
                                                return (
                                                    <NavLink
                                                        key={`${label}-${index}-${link}`}
                                                        to={link}
                                                        onClick={() => setMobile(false)}
                                                        className={({ isActive }) => {
                                                            return classNames(
                                                                'block py-2 pr-3 pl-3 border-b md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0 md:dark:hover:bg-transparent',
                                                                isActive
                                                                    ? 'text-primary'
                                                                    : 'text-gray-700',
                                                            )
                                                        }}                                                      
                                                    >
                                                        {t(`${label}`)}
                                                    </NavLink>
                                                )
                                            }
                                            if (role.length !== 0 && role.includes(userRole)) {
                                                return (
                                                    <NavLink
                                                        key={`${label}-${index}-${link}`}
                                                        to={link}
                                                        onClick={() => setMobile(false)}
                                                        className={({ isActive }) => {
                                                            return classNames(
                                                                'block py-2 pr-3 pl-3 border-b md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0 md:dark:hover:bg-transparent',
                                                                isActive
                                                                    ? 'text-primary'
                                                                    : 'text-gray-700',
                                                            )
                                                        }}
                                                    >
                                                        {t(`${label}`)}
                                                    </NavLink>
                                                )
                                            }
                                            return null
                                        },
                                    )}
                                <li className="block p-3 text-gray-700 md:hidden">
                                    {dropDownContent}
                                </li>
                            </ul>
                        </div>

                        <div className="hidden md:flex gap-4 items-center">
                            <div className="w-12">
                                <Translation />
                            </div>
                            <ul className="flex flex-col mt-4 md:flex-row md:items-center md:space-x-8 md:mt-0 md:text-sm md:font-bold">
                                {navIcons &&
                                    navIcons.map(({ icon, link }, index) => (
                                        <li key={`${icon}-${index}`}>
                                            <a
                                                href={link}
                                                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 dark:hover:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                                            >
                                                {icon}
                                            </a>
                                        </li>
                                    ))}
                                <li>{dropDownContent}</li>
                            </ul>
                        </div>
                    </>
                ) : (
                    <Link to={LOGIN_ROUTE}>
                        <Button variant="primary" label={t('api.login')} />
                    </Link>
                )}
            </div>
        </nav>
    )
}

export default Header
